import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';

import GNavbar from "../../components/navbar";

import * as paymentHttpServices from "../../services/payment-http-services";
import * as orderHttpServices from "../../services/order-http-services";

import ImgTDMSLogo from '../../assets/images/payment_tdms.png'

function OrderDetail() {

    let { orderId } = useParams();


    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();
    const { error, liff, isLoggedIn, ready } = useLiff();

    const [order, setOrder] = useState({})
    const [qrcode, setQrCode] = useState('')
    const [productTitle, setProductTitle] = useState('')

    const handleClickBackToOrderLists = () => {
        navigate("/order/lists")
    }

    const handleClickRefreshPayment = () => {
        orderHttpServices.getOrderDetailById(lineAccessToken, orderId)
            .then((response) => {
                console.log(response.data)
                setOrder(response.data.order)
            })
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        orderHttpServices.getOrderDetailById(lineAccessToken, orderId)
            .then((response) => {
                console.log(response.data)
                setOrder(response.data.order)

                if (response.data.order.type === 0) {
                    setProductTitle('Wallpaปัง')
                } else if (response.data.order.type === 1) {
                    setProductTitle('แพคเกจ')
                } else if (response.data.order.type === 2) {
                    setProductTitle(' Boxset ')
                } else if (response.data.order.type === 3) {
                    setProductTitle(' การ์ดหอมเสริมดวง ')
                }

                if (response.data.order.qrcode === null && response.data.order.sum_price !== 0) {
                    paymentHttpServices.makeQrPaymentByOrderId(lineAccessToken, orderId)
                        .then((response) => {
                            setQrCode(response.data.data.data.qr_image)
                        })
                } else {
                    setQrCode(response.data.order.qrcode)
                }
            })

    }, []);

    return (
        <>
            <GNavbar title={"ตรวจสอบการสั่งซื้อ" + productTitle} navigation={"/order/lists"} />
            <div className="wallpaperBg">
                <div className="wallpaperBox">
                    <Row>
                        {(order['wallpaper'] && order.type === 0) && <Col xs="3">
                            <img src={order['wallpaper']['thumbnail_img']} className="wallpaperThumbnail" />
                        </Col>
                        }
                        {(order['card'] && order.type === 3) && <Col xs="3">
                            <img src={order['card']['image']} className="wallpaperThumbnail" />
                        </Col>
                        }
                        <Col xs="9">
                            <p className="wallpaperOrderHeaderText">
                                คุณได้ทำการสั่งซื้อ{productTitle}
                            </p>
                            <h4>
                                {order['wallpaper'] && order['wallpaper']['name']}
                                {order['package'] && order['package']['package_name']}
                                {order['boxset'] && order['boxset']['boxset_name']}
                                {order['card'] && order['card']['card_name']}
                            </h4>
                            <p style={{ color: '#2c3e50', fontWeight: 400 }}>
                                {order['boxset'] && order['boxset']['description']}
                                {order['card'] && order['card']['description']}
                            </p>
                        </Col>
                    </Row>

                    {(order['order_status'] === 1 && order['wallpaper'] && order.type === 0 && order.wallpaper_url !== null) ? <Col xs="12">
                        <p style={{ color: '#2c3e50' }}>เซฟวอลเปเปอร์ของคุณด้านล่างได้เลย</p>
                        <img src={order['wallpaper_url']} className="wallpaperThumbnail" />
                    </Col> : <></>
                    }

                    {(order['order_status'] === 1 && order['card'] && order.type === 3) ? <Col xs="12">
                        <Row>
                            <Col xs="12" className="wallpaperOrderSummary">
                                <p style={{ color: '#2c3e50' }}>
                                    ทีมงานจะทำการจัดส่งสินค้าไปยังที่อยู่
                                    <br />
                                    <br />
                                    {
                                        order['address']
                                    }
                                    <br />เบอร์โทรศัพท์ :
                                    {
                                        order['phone_number']
                                    }
                                    <br />
                                    <br />
                                    และระบบทำการเพิ่ม Quota วอลเปเปอร์ของคุณให้เรียบร้อย จำนวน 1 ภาพ</p>
                            </Col>
                        </Row>
                    </Col> : <></>
                    }
                    <br />
                    <strong style={{ color: '#2c3e50' }}>สถานะการชำระเงิน</strong> : {order['order_status'] === 1 ? <strong style={{ color: 'green' }}>ชำระเงินแล้ว</strong> : <><strong style={{ color: 'red' }}>ยังไม่ชำระเงิน</strong></>}
                    {order['order_status'] === 0 && <Row>
                        <Col xs="12" className="wallpaperShortDescription">
                            <h1 className="wallpaperOrderSummaryHeader">QR Code สำหรับชำระเงิน</h1>
                            <strong>กรุณานำ QR Code ด้านล่างไปชำระเงินผ่าน Application ของธนาคาร</strong><br /><br />

                            <img className="wallpaperImage" src={ImgTDMSLogo} />
                            <img className="wallpaperImage" src={qrcode} />
                            <div style={{ textAlign: 'center', backgroundColor: '#2c3e50', color: '#000000' }}>
                                บริษัท มูเทคโนโลยี จำกัด <br />
                                ยอดเงิน: {(Number(order['money_received'])).toFixed(2)} <br />
                                รายการชำระเงินจะหมดอายุ: 23:59:59<br /><br />
                            </div>
                            <br />
                            <Button onClick={handleClickRefreshPayment}>
                                กดที่นี่หลังจากชำระเงินผ่าน QR แล้ว
                            </Button>
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs="12" className="wallpaperOrderSummary">
                            <h1 className="wallpaperOrderSummaryHeader">สรุปรายการสั่งซื้อ</h1>

                            {order.type === 0 && <strong>รายการWallpaปัง<br /><br /></strong>}

                            {(order['wallpaper'] || order['package']) && (<Row>
                                <Col xs="8">
                                    {order['wallpaper'] && order['wallpaper']['name']}
                                    {order['package'] && order['package']['package_name']}
                                </Col>
                                <Col xs="4" style={{ textAlign: 'right' }}>
                                    {order['wallpaper'] && Number(order['wallpaper']['cost_unit']).toFixed(2)}
                                    {order['package'] && Number(order['package']['price']).toFixed(2)}
                                </Col>
                                <hr />
                            </Row>)
                            }

                            {order['toppings'] && (<strong>รายการท็อปปิ้งเสริมดวง<br /><br /></strong>)}

                            {order['toppings'] && (order['toppings'].map((topping, i) => {
                                return (
                                    <Row key={i}>
                                        <Col xs="8">
                                            {topping['name']}
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {Number(topping['price']).toFixed(2)}
                                        </Col>
                                        <hr />
                                    </Row>
                                )
                            }))
                            }
                            <br />
                            {(order['wallpaper'] || order['package'] || order['boxset'] || order['card']) && (
                                <>
                                    <Row>
                                        <Col xs="8">
                                            <strong>รวมทั้งหมด</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong>{Number(order['sum_price']).toFixed(2)}</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <strong>ส่วนลดจากโค้ด: {order['discount_code']} {order['discount_percent'] && '(' + order['discount_percent'] + '%)'}</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong>{Number(order['discount']).toFixed(2)}</strong>
                                        </Col>
                                        <hr />
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <strong style={{ fontSize: 18 }}>ราคารวมส่วนลด</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong style={{ fontSize: 18 }}>{(Number(order['money_received'])).toFixed(2)}</strong>
                                        </Col>
                                        <hr />
                                    </Row>
                                </>
                            )}


                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Button onClick={handleClickBackToOrderLists}>
                            กลับหน้ารายการคำสั่งซื้อ
                        </Button>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default OrderDetail;