import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShirt, faStar } from '@fortawesome/free-solid-svg-icons';

import * as userHttpServices from '../services/user-http-services';
import GNavbar from "../components/navbar";
import Calendar from "react-calendar";
import SectionHeaderLine from "../components/section-header-line";
import dayjs from "dayjs";
import DailyTiming from "../components/daily-timing";
import DailyColors from "../components/daily-colors";
import { thaiFullDateFormatDayJs } from "../utils";
import ProfileInformation from "../components/profile-information";
import TabBar from "../components/tab-bar";


function Planner() {

    let { year, month, date } = useParams();


    const kanlayok = ['', 'ธงไชย', 'อธิบดี', 'อุบาทว์', 'โลกาวินาศ']
    const kanlayokText = ['', 'เป็นวันดี ทำการใดสำเร็จได้ผลดี มีชัยชนะ', 'เป็นวันดี ให้คุณเรื่องอำนาจ บารมี เป็นใหญ่', 'เป็นวันไม่ดี ทำการค้าไม่ขึ้น โชคร้าย อุบัติเหตุ', 'เป็นวันไม่ดี วุ่นวาย ยุ่งยาก ติดขัด ระส่ำระสาย ความหายนะ']

    const taksaDateText = ['', 'กาลกิณี', 'ศรี']
    const taksaDateDescription = ['', 'อุปสรรค ขัดขวาง สิ่งไม่ดี', 'สิริมงคล เงินทอง รุ่งเรือง']

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [planner, setPlanner] = useState(null)

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedDateType, setSelectedDateType] = useState("")
    const [selectedDatePosition, setSelectedDatePosition] = useState(-1)

    const { error, liff, isLoggedIn, ready } = useLiff()
    let navigate = useNavigate();
    const { state: targetDate } = useLocation()
    // const { targetDay, targetMonth, targetYear } = targetDate

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    const handleClickPackageBoxsetMenu = () => {
        navigate("/package_boxset/shop")
    }

    const loadSpecialDay = function (value, splanner) {
        let dateType = ""
        let datePosition = -1
        let selected = dayjs(value).format('YYYY-MM-DD')

        Object.keys(splanner.date).forEach(function (type) {
            splanner.date[type].map((sdate, index) => {
                if (sdate === selected) {
                    dateType = type
                    datePosition = index
                    return
                }
            })
        });

        setSelectedDateType(dateType)
        setSelectedDatePosition(datePosition)
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
                setPlanner(response.data.data.planner)
                if (response.data.data.planner !== null)
                    loadSpecialDay(dayjs().toDate(), response.data.data.planner)
            }
        })
    }, []);

    useEffect(() => {
        // console.log(targetDay, targetMonth, targetYear)
        // console.log('use effect', dayjs().toDate())
        setSelectedDate(dayjs().toDate())
    }, []);

    return (
        <>
            <TabBar selectedIndex={1} />
            <div className="homeBox">
                {personalData && <ProfileInformation user={user} personalData={personalData} line={line} />}
                {gpackage && <div>
                    <Row>
                        <Col xs="12">
                            {personalData && <SectionHeaderLine header={'วางแผนยามล่วงหน้า ' + user.duration + ' วัน'} />}
                            {planner && <Calendar
                                className="planner-calendar"
                                locale="th-TH"
                                minDate={dayjs().toDate()}
                                maxDate={dayjs().add(user.duration, 'day').toDate()}
                                tileClassName={({ date, view }) => {

                                    if (planner.date.best.includes(dayjs(date).format('YYYY-MM-DD'))) {
                                        return 'planner-day-best'
                                    }

                                    if (planner.date.good.includes(dayjs(date).format('YYYY-MM-DD'))) {
                                        return 'planner-day-good'
                                    }

                                    if (planner.date.bad.includes(dayjs(date).format('YYYY-MM-DD'))) {
                                        return 'planner-day-bad'
                                    }
                                }}

                                onClickDay={((value) => {
                                    let clickTime = dayjs(value).set('hour', dayjs().hour()).set('minute', dayjs().minute()).toDate()
                                    // console.log('click', clickTime)
                                    setSelectedDate(clickTime)
                                    loadSpecialDay(clickTime, planner)
                                })}
                            />}
                        </Col>
                    </Row>
                    <Row className="calendar-legend">
                        <Col xs="1">
                            <div className="icon-today"></div>
                        </Col>
                        <Col xs="2" className="text-calendar-desc">
                            วันนี้
                        </Col>
                        <Col xs="1">
                            <div className="icon-best-day"></div>
                        </Col>
                        <Col xs="2" className="text-calendar-desc">
                            วันดีมาก
                        </Col>
                        <Col xs="1">
                            <div className="icon-good-day"></div>
                        </Col>
                        <Col xs="2" className="text-calendar-desc">
                            วันดีปี
                        </Col>
                        <Col xs="1">
                            <div className="icon-bad-day"></div>
                        </Col>
                        <Col xs="2" className="text-calendar-desc">
                            วันไม่ดีปี
                        </Col>
                    </Row>
                    {personalData && <Row className="calendar-box">
                        วันที่เลือกคือ {thaiFullDateFormatDayJs(dayjs(selectedDate))}<br />
                        {/* {selectedDateType} {selectedDatePosition} */}
                        {
                            selectedDateType !== "best" && selectedDatePosition !== -1 ?
                                "เป็นวัน" + kanlayok[planner['description'][selectedDateType][selectedDatePosition]['kanlayok']] + " "
                                + kanlayokText[planner['description'][selectedDateType][selectedDatePosition]['kanlayok']]
                                + ((planner['description'][selectedDateType][selectedDatePosition]['taksa'] > 0) ?
                                    ((planner['description'][selectedDateType][selectedDatePosition]['kanlayok'] > 0 ?
                                        " และยังเป็นวัน" : "") + taksaDateText[planner['description'][selectedDateType][selectedDatePosition]['taksa']] + " มีความเป็น "
                                        + taksaDateDescription[planner['description'][selectedDateType][selectedDatePosition]['taksa']] + " ของคุณ") : "") :
                                selectedDateType !== "best" ? "เป็นวันปกติ คุณสามารถดูชั่วโมงยามมงคลของวันนี้ได้ จากตารางด้านล่าง" : "วันดีมากตามดวงคุณ! สำเร็จทุกประการ ดูยามดีเพิ่มเติมได้ตามตารางด้านล่าง จะช่วยให้คุณสำเร็จแบบทวีคูณ"
                        }
                    </Row>}
                    <Row className="section-v4">
                        {personalData && <DailyTiming date={dayjs(selectedDate)} birthday={personalData.day_of_birth} isPlanner={true} isBlink={false} />}
                    </Row>
                    <Row className="section-v4">
                        <h4>สีมงคลประจำวัน <FontAwesomeIcon size="sm" icon={faShirt} color="#d7ae47" /></h4>
                        <h6>{thaiFullDateFormatDayJs(dayjs(selectedDate))}</h6>
                        {personalData && <DailyColors date={dayjs(selectedDate)} />}
                    </Row>
                </div>}
                {!gpackage && <div className="planner-buy-package">
                    กรุณาซื้อแพคเกจ เพื่อใช้ระบบวางแผนยาม<br /><br />
                    <Button variant="primary" onClick={handleClickPackageBoxsetMenu}>
                        สั่งซื้อแพคเกจ คลิก!!
                    </Button>
                </div>
                }
            </div>
            <Row style={{ marginTop: 100 }}>

            </Row>
        </>
    )
}

export default Planner;
