const SectionHeaderLine = ({ header }) => {

    return (
        <div className="header-line-v4">
            <div className="header-line-v4-box">
                {header}
            </div>
        </div>
    )
}

export default SectionHeaderLine;