
export const lineUserLogin = (lineAccessToken) => dispatch => {
    dispatch({
        type: 'LINE_USER_LOGIN',
        lineAccessToken: lineAccessToken
    })
}

export const saveUserData = (userData) => dispatch => {
    dispatch({
        type: 'SAVE_USER_DATA',
        userData: userData
    })
}

export const saveUserLineData = (userLineData) => dispatch => {
    dispatch({
        type: 'SAVE_USER_LINE_DATA',
        userLineData: userLineData
    })
}