import { Col, Row } from "react-bootstrap";
import { getThaiDayName, padLeadingZeros } from "../utils";

const ProfileInformation = ({ greeting = "สวัสดีคุณ", user, personalData, line }) => {

    return (
        <Row>
            <Col xs="9" className="proflie-information-box">
                <p className="text-name">
                    {greeting} {user.name} {user.surname} <br />
                </p>
                <p className="text-detail">
                    {personalData && personalData.text_birth} <br />
                    {user.no_birth_time === 0 ? 'เวลา ' + padLeadingZeros(user.birth_hour, 2) + ':' + padLeadingZeros(user.birth_minute, 2) + ' น. ' : 'ไม่ทราบเวลาเกิด'}
                    {user.birth_hour > 6 ? "" : "(เกิดคืนวัน" + getThaiDayName(personalData.day_of_birth) + ")"}  <br />
                    {personalData && personalData.text_birth_place} <br />
                    {personalData && ('ลัคนาคุณคือ ' + personalData.text_lukkana)}
                </p>
            </Col>
            <Col xs="3">
                {line && <img className="profileImage" src={line.pictureUrl} width={"100%"} />}
            </Col>
        </Row>
    )
}

export default ProfileInformation;