import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import * as userHttpServices from '../services/user-http-services';

import BtnOpenCard from '../assets/images/btn-horacle.png'
import LogoMeemon from '../assets/images/logo_meemon.png'
import TabBar from "../components/tab-bar";
import SectionHeaderLine from "../components/section-header-line";
import { getLuckMeemon, getLuckYamMahalap } from "../utils";
import dayjs from "dayjs";
import ProfileInformation from "../components/profile-information";

function Luck() {

    const { error, liff, isLoggedIn, ready } = useLiff()
    const [dailyCard, setDailyCard] = useState([])

    const [meemon, setMeemon] = useState([])
    const [mahalap, setMahalap] = useState([])

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)

    let navigate = useNavigate();

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    const handleClickHoracle = () => {
        navigate("/horacle")
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
            }
        })

        setMeemon(getLuckMeemon(dayjs()))
        setMahalap(getLuckYamMahalap(dayjs()))

    }, []);

    return (
        <>
            <TabBar selectedIndex={0} />
            <div className="homeBox">
                {personalData && <ProfileInformation user={user} personalData={personalData} line={line} />}
                <SectionHeaderLine header={'เลขมงคลรายวัน'} /><br />
                <Row>
                    <Col xs="3">
                        <img src={LogoMeemon} width={'100%'} />
                    </Col>
                    <Col xs="9" className="luck-number-box">
                        <Row>
                            {
                                meemon.map((number) => {
                                    return (
                                        <Col key={"meemon_" + number} xs="4" className="luck-number" >
                                            {number}
                                        </Col>
                                    )
                                })
                            }
                            <Col xs="4" className="luck-number">
                                <img src={LogoMeemon} width={'30%'} />
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <p className="text-luck-desc">
                    ตัวเลขมีมนตร์อัพเดตทุกวันที่นี่
                </p>
                <Row>
                    <Col xs="12" className="luck-number-box">
                        <Row>
                            {
                                mahalap.map((number) => {
                                    return (
                                        <Col key={"mhl_" + number} xs="3" className="luck-number" >
                                            {number}
                                        </Col>
                                    )
                                })
                            }
                            <Col xs="3" className="luck-number">

                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xs="3">
                        <img src={LogoMeemon} width={'100%'} />
                    </Col> */}
                </Row >
                <p className="text-luck-desc">
                    วิชาเลขมหาภูติพม่า อ.โอม
                </p>
                <Row>
                    <SectionHeaderLine header={'เปิดไพ่ลุ้นดวงรายวัน'} />
                    <div className="btn-card-box">
                        <img src={BtnOpenCard} className="btn-card" onClick={handleClickHoracle} />
                    </div>
                </Row>
            </div >
            <Row style={{ marginTop: 100 }}>

            </Row>
        </>
    )
}

export default Luck;
