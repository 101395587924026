import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';

import GNavbar from "../../components/navbar";

import * as orderHttpServices from "../../services/order-http-services";


function OrderLists() {


    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();
    const { error, liff, isLoggedIn, ready } = useLiff();

    const [orders, setOrders] = useState([])

    const handleClickOrder = (id) => {
        navigate("/order/" + id)
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        orderHttpServices.getUserOrders(lineAccessToken)
            .then((response) => {
                if (response.data.success) {
                    setOrders(response.data.data)
                }
            })

    }, []);

    return (
        <>
            <GNavbar title="รายการคำสั่งซื้อของคุณ" navigation={"/shop"} />
            <div className="wallpaperBg">
                <div className="orderBox">
                    {
                        orders.map((order, i) => {
                            return (
                                <Row key={order.order_id} onClick={() => handleClickOrder(order.order_id)}>
                                    <Col xs="12" className="wallpaperShortDescription">
                                        [รายการที่ {orders.length - i}] ซื้อ{order.type === 0 ? 'Wallpaปัง' : 'แพคเกจ'} ราคา {Number(order.money_received).toFixed(2)} บาท {">"}
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default OrderLists;
