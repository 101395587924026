import { useEffect, useState } from 'react';
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import * as userHttpServices from '../services/user-http-services';
import * as referralHttpServices from '../services/referral-http-services';
import * as userAction from '../redux/actions/user-action';

import LogoImg from '../assets/images/om168-gold-logo.png'
import { padLeadingZeros } from '../utils';
import provincesData from '../datas/provinces.json';

function Register() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const saveUserData = userData => dispatch(userAction.saveUserData(userData))
    const saveUserLineData = userLineData => dispatch(userAction.saveUserLineData(userLineData))

    const { lineAccessToken } = useSelector(
        state => state.userReducer,
    );

    const thaiMonthName = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

    const [showPrivacyModal, setShowPrivacyModal] = useState(false)
    const handleClosePrivacyModal = () => setShowPrivacyModal(false)
    const handleShowPrivacyModal = () => setShowPrivacyModal(true)

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = () => setShowConfirmModal(true)

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [gender, setGender] = useState('')
    const [phone, setPhone] = useState('')
    const [province, setProvince] = useState(1)
    const [birthDate, setBirthDate] = useState(1)
    const [birthMonth, setBirthMonth] = useState(1)
    const [birthYear, setBirthYear] = useState(2000)
    const [birthHour, setBirthHour] = useState(8)
    const [birthMinute, setBirthMinute] = useState(0)
    const [noBirthTime, setNoBirthTime] = useState(false)
    const [pdpaConsent, setPdpaConsent] = useState(true)
    const [allValidated, setAllValidated] = useState(true)

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        setAllValidated(
            !(
                pdpaConsent
                && name !== ''
                && surname !== ''
                && gender !== ''
                && phone.length == 10
            )
        )
    }, [name, surname, gender, province, phone, pdpaConsent]);


    const handleClickRegister = () => {
        // console.log(
        //     "\npdpa", pdpaConsent,
        //     "\nbt", noBirthTime,
        //     "\nref", referralCode,
        //     "\nmin", birthMinute,
        //     "\nhour", birthHour,
        //     "\nyear", birthYear,
        //     "\nmonth", birthMonth,
        //     "\ndate", birthDate,
        //     "\nprov", province,
        //     "\ngender", gender,
        //     "\nsurname", surname,
        //     "\nname", name,
        // )

        const registerData = {
            name: name,
            surname: surname,
            gender: gender,
            birth_date: birthYear + '-' + padLeadingZeros(birthMonth, 2) + '-' + padLeadingZeros(birthDate, 2),
            birth_hour: birthHour,
            birth_minute: birthMinute,
            no_birth_time: noBirthTime ? 1 : 0,
            province_id: province,
            pdpa_consent: 1,
            ref_code: "CANCEL",
            phone_number: phone
        };

        userHttpServices.lineRegister(lineAccessToken, registerData)
            .then((response) => {
                let userData = response.data
                if (userData.success) {
                    saveUserData(userData.data)
                    saveUserLineData(userData.line_data)
                    navigate("/home")
                } else {
                    //Duplicate user
                }
            }).catch(err => {
                console.log('register err', err)
            })
    }

    const handleOnValueChange = (e) => {
        let id = e.target.id
        if (id === "pdpaConsent") {
            setPdpaConsent(!pdpaConsent)
        } else if (id === "noBirthTime") {
            setNoBirthTime(!noBirthTime)
        } else if (id === "phone") {
            setPhone(e.target.value)
        } else if (id === "birthMinute") {
            setBirthMinute(e.target.value)
        } else if (id === "birthHour") {
            setBirthHour(e.target.value)
        } else if (id === "birthYear") {
            setBirthYear(e.target.value)
        } else if (id === "birthMonth") {
            setBirthMonth(e.target.value)
        } else if (id === "birthDate") {
            setBirthDate(e.target.value)
        } else if (id === "province") {
            setProvince(e.target.value)
        } else if (id === "gender") {
            setGender(e.target.value)
        } else if (id === "surname") {
            setSurname(e.target.value)
        } else if (id === "name") {
            setName(e.target.value)
        }
    }

    return (
        <>
            <Modal show={showPrivacyModal} onHide={handleClosePrivacyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>นโยบายความเป็นส่วนตัวสำหรับลูกค้า</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pRegisPdpa'>
                    <p>
                        บริษัท มูเทคโนโลยี จำกัด ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    </p>
                    <br />
                    <h4>การเก็บรวบรวมข้อมูลส่วนบุคคล</h4>
                    <p>
                        เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
                    </p>
                    <ul>
                        <li>การสมัครสมาชิก</li>
                        <li>โทรศัพท์</li>
                        <li>อีเมล</li>
                        <li>Facebook Login</li>
                        <li>Google Login</li>
                        <li>LINE Login</li>
                        <li>Twitter Login</li>
                    </ul>
                    <p>
                        เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณที่เราเข้าถึงได้จากแหล่งอื่น เช่น เสิร์ชเอนจิน โซเชียลมีเดีย หน่วยงานราชการ บุคคลภายนอกอื่น ๆ เป็นต้น
                    </p>
                    <br />

                    <h4>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h4>
                    <p>
                        <strong>ข้อมูลส่วนบุคคล</strong> เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สัญชาติ เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น <br />
                        <strong>ข้อมูลการติดต่อ</strong> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น<br />
                        <strong>ข้อมูลบัญชี</strong> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น<br />
                        <strong>หลักฐานแสดงตัวตน</strong> เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง เป็นต้น<br />
                        <strong>ข้อมูลการทำธุรกรรมและการเงิน</strong> เช่น ประวัติการสั่งซื้อ รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น<br />
                        <strong>ข้อมูลทางเทคนิค</strong> เช่น IP address, Cookie ID, ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น<br />
                        <strong>ข้อมูลอื่น ๆ</strong> เช่น รูปภาพ ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล<br />
                        เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว ดังต่อไปนี้ เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ เว้นแต่กฎหมายกำหนดให้ทำได้
                    </p>

                    <ul>
                        <li>เชื้อชาติ</li>
                        <li>เผ่าพันธุ์</li>
                        <li>ศาสนาหรือปรัชญา</li>
                        <li>ประวัติอาชญากรรม</li>
                        <li>พฤติกรรมทางเพศ</li>
                        <li>ข้อมูลสุขภาพ</li>
                        <li>ความพิการ</li>
                        <li>ข้อมูลสหภาพแรงงาน</li>
                    </ul>
                    <p>
                        ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                    </p>


                    <h4>ผู้เยาว์</h4>
                    <p>
                        หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย เราอาจเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้ หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
                    </p>

                    <h4>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h4>
                    <p>
                        เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
                        เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้
                    </p>
                    <ul>
                        <li>เซิร์ฟเวอร์บริษัทของเราในประเทศไทย</li>
                    </ul>

                    <h4>การประมวลผลข้อมูลส่วนบุคคล</h4>
                    <p>
                        เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้
                    </p>
                    <ul>
                        <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
                        <li>เพื่อจัดส่งสินค้าหรือบริการ</li>
                        <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
                        <li>เพื่อการบริหารจัดการภายในบริษัท</li>
                        <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
                        <li>เพื่อรวบรวมข้อเสนอแนะ</li>
                        <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
                        <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
                        <li>เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ</li>
                    </ul>
                    <h4>การเปิดเผยข้อมูลส่วนบุคคล</h4>
                    <p>
                        เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้ ดังต่อไปนี้
                    </p>

                    <h4>การบริหารจัดการภายในองค์กร</h4>
                    <p>
                        เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของเรา เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น
                    </p>

                    <h4>ผู้ให้บริการ</h4>
                    <p>
                        เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณบางอย่างให้กับผู้ให้บริการของเราเท่าที่จำเป็นเพื่อดำเนินงานในด้านต่าง ๆ เช่น การชำระเงิน การตลาด การพัฒนาสินค้าหรือบริการ เป็นต้น ทั้งนี้ ผู้ให้บริการมีนโยบายความเป็นส่วนตัวของตนเอง
                    </p>

                    <h4>พันธมิตรทางธุรกิจ</h4>
                    <p>
                        เราอาจเปิดเผยข้อมูลบางอย่างกับพันธมิตรทางธุรกิจเพื่อติดต่อและประสานงานในการให้บริการสินค้าหรือบริการ และให้ข้อมูลเท่าที่จำเป็นเกี่ยวกับความพร้อมใช้งานของสินค้าหรือบริการ
                    </p>

                    <h4>การถ่ายโอนธุรกิจ</h4>
                    <p>
                        เราอาจเปิดเผยข้อมูล รวมถึงข้อมูลส่วนบุคคลของคุณ สำหรับการปรับโครงสร้างองค์กร การควบรวมหรือการขายกิจการ หรือการถ่ายโอนสินทรัพย์อื่น ๆ โดยฝ่ายที่รับโอนต้องปฏิบัติกับข้อมูลของคุณในลักษณะที่สอดคล้องกับนโยบายนี้ รวมถึงกฎหมายคุ้มครองข้อมูลส่วนบุคคลด้วย
                    </p>

                    <h4>การบังคับใช้กฎหมาย</h4>
                    <p>
                        ในกรณีที่มีกฎหมายหรือหน่วยงานราชการร้องขอ เราจะเปิดเผยข้อมูลส่วนบุคคลของคุณเท่าที่จำเป็นให้แก่หน่วยงานราชการ เช่น ศาล หน่วยงานราชการ เป็นต้น
                    </p>

                    <h4>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</h4>
                    <p>
                        เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
                    </p>

                    <h4>สิทธิของเจ้าของข้อมูลส่วนบุคคล</h4>
                    <p>
                        ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล  คุณมีสิทธิในการดำเนินการดังต่อไปนี้
                        <br />
                        <br />
                        สิทธิขอถอนความยินยอม (right to withdraw consent) หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
                        <br />
                        <br />
                        สิทธิขอเข้าถึงข้อมูล (right to access) คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ  รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
                        <br />
                        <br />
                        สิทธิขอถ่ายโอนข้อมูล (right to data portability) คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วย
                        วิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
                        เมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุม
                        ข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
                        <br />
                        <br />
                        สิทธิขอคัดค้าน (right to object)  คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม
                        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจ
                        เพื่อสาธารณประโยชน์
                        <br />
                        <br />
                        สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction) คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่
                        ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม
                        ใช้หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้
                        ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
                        <br />
                        <br />
                        สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing) คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอ
                        แก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลาย
                        ข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
                        <br />
                        <br />
                        สิทธิขอให้แก้ไขข้อมูล (right to rectification) คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                        <br />
                        <br />
                        สิทธิร้องเรียน (right to lodge a complaint) คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
                        <br />
                        <br />
                        คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
                    </p>

                    <h4>การโฆษณาและการตลาด</h4>
                    <p>
                        เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของเรา เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าหรือบริการ และทำการตลาดผ่าน Google, Facebook, pixel tracking code และอื่น ๆ เราใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ
                        เว็บไซต์ของเราอาจแสดงโฆษณาจากบุคคลที่สามเพื่ออำนวยความสะดวกในการบริการของเรา เช่น Google AdSense, BuySellAds บุคคลที่สามเหล่านี้อาจเข้าถึงข้อมูลส่วนบุคคลของคุณเพื่อดำเนินการเหล่านี้เท่านั้น และมีหน้าที่ไม่เปิดเผยหรือใช้เพื่อวัตถุประสงค์อย่างอื่น
                        เราอาจส่งข้อมูลหรือจดหมายข่าวไปยังอีเมลของคุณ โดยมีวัตถุประสงค์เพื่อเสนอสิ่งที่น่าสนกับคุณ หากคุณไม่ต้องการรับการติดต่อสื่อสารจากเราผ่านทางอีเมลอีกต่อไป คุณสามารถกด "ยกเลิกการติดต่อ" ในลิงก์อีเมลหรือติดต่อมายังอีเมลของเราได้
                    </p>

                    <h4></h4>เทคโนโลยีติดตามตัวบุคคล (Cookies)
                    <p>
                        เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้
                    </p>
                    <h4></h4>การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล
                    <p>
                        เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)
                    </p>

                    <h4></h4>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล
                    <p>
                        ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น  เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
                    </p>
                    <h4></h4>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
                    <p>
                        เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
                        <br />
                        นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 27 มีนาคม 2565
                    </p>
                    <h4></h4>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น
                    <p>
                        นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย
                    </p>
                    <h4></h4>รายละเอียดการติดต่อ
                    <p>
                        หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้ ดังนี้
                    </p>
                    <h4></h4>ผู้ควบคุมข้อมูลส่วนบุคคล
                    <p>
                        บริษัท มูเทคโนโลยี จำกัด<br />
                        เลขที่ 206/160 เดอะเมทโทรสาทร ถนนกัลปพฤกษ์ แขวงบางหว้า เขตภาษีเจริญ กรุงเทพมหานคร<br />
                        อีเมล ganesha168.com@gmail.com
                    </p>
                    <h4></h4>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                    <p>
                        OM168<br />
                        เลขที่ 206/160 เดอะเมทโทรสาทร ถนนกัลปพฤกษ์ แขวงบางหว้า เขตภาษีเจริญ กรุงเทพมหานคร<br />
                        อีเมล ganesha168.com@gmail.com<br />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClosePrivacyModal}>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการสมัคร</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='desc'>
                        หากท่านกดยืนยันแล้วจะกลับไปแก้ไขข้อมูลส่วนตัวไม่ได้ เพื่อความแม่นยำในการทำนาย กรุณาตรวจสอบรายละเอียดของท่าน<br /><br />
                        <strong className='strong-red'>รบกวนตรวจสอบข้อมูลก่อนยืนยัน ถ้าหากมีการเปลี่ยนแปลงข้อมูลจะมีค่าบริการเพิ่มเติม</strong>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseConfirmModal}>
                        กลับไปแก้ไข
                    </Button>
                    <Button variant="primary" onClick={handleClickRegister}>
                        ยืนยันการสมัคร
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="regisHeader">
                <h1>ลงทะเบียนเข้าสู่ระบบ</h1>
                <img src={LogoImg} className="regisLogo" />
            </div>
            <div className="regisForm">
                <h4>กรอกข้อมูลเพื่อคำนวณดวงชะตา</h4>
                <p className='desc'>
                    กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน เพื่อความแม่นยำในการทำนาย โดยวิชาที่ใช้ในการทำนายรวบรวมมาจากหลากหลายศาสตร์ ครบถ้วนที่นี่ที่เดียว
                </p>
                <hr />
                <Row>
                    <Col xs="5" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>ชื่อ</Form.Label>
                            <Form.Control size="sm" placeholder="ชื่อจริง" id="name" defaultValue={name} onChange={(handleOnValueChange)} />
                        </Form.Group>
                    </Col>
                    <Col xs="7" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>นามสกุล</Form.Label>
                            <Form.Control size="sm" placeholder="นามสกุล" onChange={(handleOnValueChange)} defaultValue={surname} id="surname" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>เพศกำเนิด</Form.Label>
                            <Form.Select onChange={handleOnValueChange} defaultValue={gender} id="gender" size="sm">
                                <option value="">ระบุ</option>
                                <option value="M">ชาย</option>
                                <option value="F">หญิง</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="8" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>สถานที่เกิด</Form.Label>
                            <Form.Select onChange={(handleOnValueChange)} defaultValue={province} id="province" size="sm">
                                {
                                    provincesData.PROVINCES.map((province) => {
                                        return <option key={province.id} value={province.id}>{province.name_th}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            <Form.Control size="sm" placeholder="เบอร์โทรศัพท์" id="phone" type='number' defaultValue={phone} onChange={(handleOnValueChange)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>วัน</Form.Label>
                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={birthDate} id="birthDate">
                                {
                                    Array.from(Array(31), (e, i) => {
                                        return <option key={i} value={i + 1}>{padLeadingZeros(i + 1, 2)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="5" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>เดือน</Form.Label>
                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={birthMonth} id="birthMonth">
                                {
                                    Array.from(Array(12), (e, i) => {
                                        return <option key={i} value={i + 1}>{thaiMonthName[i]} ({padLeadingZeros(i + 1, 2)})</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="4" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>ปีเกิด</Form.Label>
                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={birthYear} id="birthYear">
                                {
                                    Array.from(Array(75), (e, i) => {
                                        return <option key={i} value={i + 2490 - 543}>{i + 2490} ({i + 2490 - 543})</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>เวลาเกิด</Form.Label>
                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={birthHour} id="birthHour" disabled={noBirthTime}>
                                {
                                    Array.from(Array(24), (e, i) => {
                                        return <option key={i} value={i}>{padLeadingZeros(i, 2)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="3" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label><br /></Form.Label>
                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={birthMinute} id="birthMinute" disabled={noBirthTime}>
                                {
                                    Array.from(Array(60), (e, i) => {
                                        return <option key={i} value={i}>{padLeadingZeros(i, 2)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="6" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label><br /></Form.Label>
                            <Form.Check
                                onChange={(handleOnValueChange)}
                                defaultChecked={noBirthTime}
                                id="noBirthTime"
                                type="checkbox"
                                label="ไม่ทราบเวลาเกิด"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                {/* <Row>
                    <Col xs="12" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Label>รหัสผู้แนะนำ</Form.Label>
                            <Form.Control size="sm" placeholder="กรอกรหัสผู้แนะนำ" onChange={(handleOnValueChange)} id="referralCode" defaultValue={referralCode} />
                            { referralCode !== "" && <p className="regisReferralCodeText">
                                {verifyReferralCode ? <strong className='strong-green'>รหัสผู้แนะนำถูกต้อง </strong> : <><strong className='strong-red'>รหัสผู้แนะนำไม่ถูกต้อง</strong></>}
                            </p>
                            }
                        </Form.Group>
                    </Col>
                </Row> */}
                <Row>
                    <Col xs="12" className='regisBox'>
                        <Form.Group className="mb-2">
                            <Form.Check
                                onChange={(handleOnValueChange)}
                                defaultChecked={pdpaConsent}
                                id="pdpaConsent"
                                type="checkbox"
                                label="ยอมรับเงื่อนไขข้อตกลง นโยบายความเป็นส่วนตัว"
                            />
                        </Form.Group>
                        <p className='desc' onClick={handleShowPrivacyModal}>คลิกที่นี่เพื่ออ่านนโยบายความเป็นส่วนตัว</p>
                    </Col>
                </Row>
                <Row>
                    <Button variant="primary" onClick={handleShowConfirmModal} disabled={allValidated}>สมัครสมาชิก</Button>
                </Row>
            </div>
        </>
    )
}

export default Register;