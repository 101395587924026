import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faStar, faHatWizard, faWandMagicSparkles, faClock, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs'
import { thaiFullDateFormatDayJs, padLeadingZeros } from '../utils';

import LogoImg from '../assets/images/om168-gold-logo-circle.png'
import BtnOpenCard from '../assets/images/btn-horacle.png'
import Menu1 from '../assets/images/menu_1.png'
import Menu2 from '../assets/images/menu_2.png'
import Menu3 from '../assets/images/boxset_button.png'
import Jakka from '../assets/images/jakka_gold-new.png'
import yamData from '../datas/sorosakan.json';

import * as userHttpServices from '../services/user-http-services';

function Home() {

    const refPersonalDuang = useRef(null)
    const refPersonalYam = useRef(null)
    const refHomeTop = useRef(null)
    const scrollToHomeTop = () => refHomeTop.current.scrollIntoView()
    const scrollToPersonalDuang = () => refPersonalDuang.current.scrollIntoView()
    const scrollToPersonalYam = () => refPersonalYam.current.scrollIntoView()

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = () => setShowConfirmModal(true)

    // const [showPromotionModal, setShowPromotionModal] = useState(true)
    // const handleShowPromotionModal = () => setShowPromotionModal(true)
    // const handleClosePromotionModal = () => setShowPromotionModal(false)


    const daysBetweenDate = (date1, date2) => {
        let difference = date2.getTime() - date1.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    const { error, liff, isLoggedIn, ready } = useLiff();

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    const { innerWidth: width, innerHeight: height } = window;

    let navigate = useNavigate();

    const taksa = [
        [1, 2, 3, 4, 7, 5, 8, 6],
        [2, 3, 4, 7, 5, 8, 6, 1],
        [3, 4, 7, 5, 8, 6, 1, 2],
        [4, 7, 5, 8, 6, 1, 2, 3],
        [5, 8, 6, 1, 2, 3, 4, 7],
        [6, 1, 2, 3, 4, 7, 5, 8],
        [7, 5, 8, 6, 1, 2, 3, 4],
        [8, 6, 1, 2, 3, 4, 7, 5],
    ]

    const khen = ['ต้องระวังมาก', 'ต้องระวัง', 'ดี', 'ดีมาก']

    const yamDayText = ['06.01 - 07.30', '07.31 - 09.00', '09.01 - 10.30', '10.31 - 12.00', '12.01 - 13.30', '13.31 - 15.00', '15.01 - 16.30', '16.31 - 18.00']
    const yamNightText = ['18.01 - 19.30', '19.31 - 21.00', '21.01 - 22.30', '22.31 - 00.00', ' 00.01 - 01.30', '01.31 - 03.00', '03.01 - 04.30', '04.31 - 06.00']
    const yamMeaningText = ['ความสำเร็จ ชัยชนะ', 'โชคลาภ การได้รับ', 'ความเมตตา เอ็นดู', 'กลาง ๆ ไม่ดี ไม่ร้าย', 'เหน็ดเหนื่อย ลำบาก', 'ไม่ดี อัปมงคล', 'เสียเปรียบ']
    const taksaText = ['บริวาร ลูกน้อง ผู้ใต้บังคับบัญชา', 'สุขภาพ ความเป็นอยู่', 'อำนาจ วาสนา บารมี', 'การเงิน สิริมงคล คุณงามความดี', 'ความมั่นคง ฐานะความเป็นอยู่', 'การงาน การเรียน ความขยัน', 'ส่งเสริม เมตตา ผู้อุปการะ', 'ต้องห้าม อุปสรรค ความทุกข์ยาก']
    const taksaColorText = ['แดง, ทอง', 'เหลือง, ขาว', 'ชมพู, บานเย็น', 'เขียว', 'ส้ม, แสด', 'ฟ้า, น้ำเงิน', 'ม่วง, ดำ', 'ดำ, เทา, ทองสำริด, น้ำตาล']
    const taksaDirection = ['ตะวันออกเฉียงเหนือ', 'ตะวันออก', 'ตะวันออกเฉียงใต้', 'ใต้', 'ตะวันตก', 'เหนือ', 'ตะวันตกเฉียงใต้', 'ตะวันตกเฉียงเหนือ']
    const taksaColor = ['#d63031', '#fdcb6e', '#e84393', '#00b894', '#e17055', '#0984e3', '#6c5ce7', '#2d3436']

    const jakkaPositions = [
        [
            // เมษ
            [{ x: 192.5, y: 85 }],
            [{ x: 192.5, y: 85 }, { x: 192.5, y: 110 }],
            [{ x: 192.5, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 60 }, { x: 205, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }, { x: 180, y: 135 }, { x: 205, y: 135 }],
        ],
        [
            // พฤษภ
            [{ x: 127.5, y: 85 }],
            [{ x: 127.5, y: 85 }, { x: 140, y: 110 }],
            [{ x: 115, y: 85 }, { x: 140, y: 85 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }, { x: 140, y: 135 }],
        ],
        [
            // มิถุน
            [{ x: 127.5, y: 85 }],
            [{ x: 127.5, y: 85 }, { x: 140, y: 110 }],
            [{ x: 115, y: 85 }, { x: 140, y: 85 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }, { x: 140, y: 135 }],
        ],
        [
            // กรกฏ
            [{ x: 192.5, y: 85 }],
            [{ x: 192.5, y: 85 }, { x: 192.5, y: 110 }],
            [{ x: 192.5, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 60 }, { x: 205, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }, { x: 180, y: 135 }, { x: 205, y: 135 }],
        ],
        [
            // สิงห์
            [{ x: 257.5, y: 85 }],
            [{ x: 257.5, y: 85 }, { x: 245, y: 110 }],
            [{ x: 270, y: 85 }, { x: 245, y: 85 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }, { x: 245, y: 135 }],
        ],
        [
            // กันย์
            [{ x: 127.5, y: 300 }],
            [{ x: 127.5, y: 300 }, { x: 140, y: 275 }],
            [{ x: 115, y: 300 }, { x: 140, y: 300 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }, { x: 140, y: 250 }],
        ],
        [
            // ตุลย์
            [{ x: 192.5, y: 300 }],
            [{ x: 192.5, y: 300 }, { x: 192.5, y: 275 }],
            [{ x: 192.5, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 325 }, { x: 205, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }, { x: 180, y: 250 }, { x: 205, y: 250 }],
        ],
        [
            // พิจิก
            [{ x: 257.5, y: 300 }],
            [{ x: 257.5, y: 300 }, { x: 245, y: 275 }],
            [{ x: 270, y: 300 }, { x: 245, y: 300 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }, { x: 245, y: 250 }],
        ],
        [
            // ธนู
            [{ x: 257.5, y: 300 }],
            [{ x: 257.5, y: 300 }, { x: 245, y: 275 }],
            [{ x: 270, y: 300 }, { x: 245, y: 300 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }, { x: 245, y: 250 }],
        ],
        [
            // มกร
            [{ x: 192.5, y: 300 }],
            [{ x: 192.5, y: 300 }, { x: 192.5, y: 275 }],
            [{ x: 192.5, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 325 }, { x: 205, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }, { x: 180, y: 250 }, { x: 205, y: 250 }],

        ],
        [
            // กุมภ์
            [{ x: 127.5, y: 300 }],
            [{ x: 127.5, y: 300 }, { x: 140, y: 275 }],
            [{ x: 115, y: 300 }, { x: 140, y: 300 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }, { x: 140, y: 250 }],
        ],
        [
            //มีน
            [{ x: 257.5, y: 85 }],
            [{ x: 257.5, y: 85 }, { x: 245, y: 110 }],
            [{ x: 270, y: 85 }, { x: 245, y: 85 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }, { x: 245, y: 135 }],
        ],
    ]

    const planetSigns = [
        "๐",
        "๑",
        "๒",
        "๓",
        "๔",
        "๕",
        "๖",
        "๗",
        "๘",
        "๙",
        "ล",
    ]

    const planYamActivities = [
        {
            "id": 0,
            "activity": "เจรจากับลูกค้าหรือผู้ใหญ่แล้วเมตตาเอ็นดู",
            "type": 2
        },
        {
            "id": 1,
            "activity": "เสี่ยงโชค เสี่ยงดวง ได้ตามที่หวัง",
            "type": 1
        },
        {
            "id": 2,
            "activity": "ต้องการความสำเร็จ ชัยชนะ",
            "type": 0
        },
        {
            "id": 3,
            "activity": "ง้อแฟนงอนหรือขอคืนดี สำเร็จ",
            "type": 2
        },
        {
            "id": 4,
            "activity": "ติดต่อธุรกิจสำเร็จผลตามที่หวัง",
            "type": 1
        },
        {
            "id": 5,
            "activity": "ขอความช่วยเหลือจากผู้อื่นให้เมตตามาช่วย",
            "type": 2
        },
        {
            "id": 6,
            "activity": "เจรจาต่อรองให้ได้เปรียบ",
            "type": 0
        },
        {
            "id": 7,
            "activity": "เจรจาให้ได้เงินตามที่คาดหวัง",
            "type": 1
        },
        {
            "id": 8,
            "activity": "นำเสนอผลงานสุดปัง ปิดการขาย",
            "type": 0
        },
        {
            "id": 9,
            "activity": "ศัลยกรรมสุดปัง",
            "type": 0
        },
        {
            "id": 10,
            "activity": "ไหว้พระขอพร",
            "type": 2
        }
    ]

    const [planetSize, setPlanetSize] = useState(0)
    const [zodiacPositions, setZodiacPositions] = useState([])
    const [today, setToday] = useState(1)
    const [todayText, setTodayText] = useState("")
    const [monthYearText, setMonthYearText] = useState("")
    const [monthlyPrediction, setMonthlyPrediction] = useState({})
    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [yamDayGood, setYamDayGood] = useState([])
    const [yamDayBad, setYamDayBad] = useState([])
    const [yamNightGood, setYamNightGood] = useState([])
    const [yamNightBad, setYamNightBad] = useState([])
    const [yamCurrentText, setYamCurrentText] = useState('')
    const [yamCurrentCss, setYamCurrentCss] = useState('')
    const [yamCurrentTime, setYamCurrentTime] = useState('')
    const [yamCurrentSao, setYamCurrentSao] = useState([])
    const [phone, setPhone] = useState('')

    // useEffect(() => {
    //     userHttpServices.checkPhoneNumber(lineAccessToken).then((response) => {
    //         if (response.data.success) {
    //             console.log('res: ', response)
    //         } else {
    //             handleShowConfirmModal()
    //             console.log('res: ', response)
    //         }
    //     })

    // }, []);

    const handleClickPromotion = () => {
        navigate('/order/confirm', {
            state: {
                "type": 3,
                "card_id": 1,
            }
        })
    }

    const handleClick = () => {
        const changeData = {
            phone_number: phone
        };

        if (changeData.phone_number !== '') {
            userHttpServices.editPhoneNumber(lineAccessToken, changeData).then((response) => {
                if (response.data.success) {
                    setShowConfirmModal()
                    console.log(response)
                    alert('บันทึกข้อมูลเสร็จสิ้น')
                } else {
                    console.log(response)
                    alert('เบอร์โทรศัพท์ของท่านถูกใช้งานแล้ว')
                }
            })
        } else {
            alert('กรุณากรอกเบอร์โทรศัพท์ของท่าน')
        }
    }

    const [planYamDateObjects, setPlanYamDateObjects] = useState([])
    const [selectedPlanYamDateValue, setSelectedPlanYamDateValue] = useState(0)
    const [selectedPlanYamActivityValue, setSelectedPlanYamActivityValue] = useState(0)
    const [selectedPlanYamHourValue, setSelectedPlanYamHourValue] = useState(9)
    const [selectedPlanYamMinuteValue, setSelectedPlanYamMinuteValue] = useState(0)
    const [planYamCurrentText, setPlanYamCurrentText] = useState('')
    const [planYamCurrentCss, setPlanYamCurrentCss] = useState('')

    useEffect(() => {
        setSelectedPlanYamDateValue(dayjs().day())

        let tmpPlanYamDateObjects = []
        for (var i = 0; i < 30; i++) {
            tmpPlanYamDateObjects.push({
                "id": i,
                "text": thaiFullDateFormatDayJs(dayjs().add(i, 'day')),
                "day": dayjs().add(i, 'day').day()
            })
        }

        setPlanYamDateObjects(tmpPlanYamDateObjects)
    }, []);

    useEffect(() => {
        if (personalData !== null) {
            const intervalId = setInterval(() => {
                let dt = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Bangkok" }))
                let secs = dt.getSeconds() + (60 * (dt.getMinutes() + (60 * dt.getHours())))

                const td = dt.getHours() < 6 ? dt.getDay() == 0 ? 7 : dt.getDay() : dt.getDay() + 1
                let dn = "day"
                let saoYam = 0

                if (secs >= 0 && secs < 21600) {
                    dn = "night"
                    saoYam = parseInt(secs / 5400) + 4
                    setYamCurrentTime(yamNightText[saoYam] + " น.")
                } else if (secs >= 21600 && secs < 64800) {
                    dn = "day"
                    saoYam = parseInt(secs / 5400) - 4
                    setYamCurrentTime(yamDayText[saoYam] + " น.")
                } else if (secs >= 64800 && secs < 86400) {
                    dn = "night"
                    saoYam = parseInt(secs / 5400) - 12
                    setYamCurrentTime(yamNightText[saoYam] + " น.")
                }

                let currYam = yamData[parseInt(td)][dn][saoYam].indexOf(personalData.day_of_birth)

                if (currYam < 3) {
                    setYamCurrentText("เป็นยามดี คุณจะได้รับ " + yamMeaningText[currYam])
                    setYamCurrentCss("yamGood")
                } else if (currYam === 3) {
                    setYamCurrentText("เป็นยามกลาง ๆ ไม่ดีไม่ร้าย")
                    setYamCurrentCss("yamMid")
                } else if (currYam > 3) {
                    setYamCurrentText("เป็นยามไม่ดี คุณต้องระวัง " + yamMeaningText[currYam])
                    setYamCurrentCss("yamBad")
                }

                setYamCurrentSao(yamData[parseInt(td)][dn][saoYam])
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [personalData]);

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        const d = new Date();
        const td = d.getHours() < 6 ? d.getDay() == 0 ? 7 : d.getDay() : d.getDay() + 1
        setToday(td)
        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setTodayText(response.data.data.today_text)
                setMonthYearText(response.data.data.current_month_year)
                setMonthlyPrediction(response.data.data.monthly_prediction)
                setGPackage(response.data.data.user.package_id)

                //CALCULATE YAM
                // console.log(yamData[parseInt(td)]['day'])
                let tmpYamDayGood = []
                let tmpYamDayBad = []
                let tmpYamNightGood = []
                let tmpYamNightBad = []

                yamData[parseInt(td)]['day'].map((yams, i) => {
                    yams.map((yam, index) => {
                        if (yam === response.data.data.personal_data.day_of_birth) {
                            if (index > 3) {
                                tmpYamDayBad.push({ "yam": i, "position": index })
                            } else if (index < 3) {
                                tmpYamDayGood.push({ "yam": i, "position": index })
                            }
                        }
                    })
                })

                yamData[parseInt(td)]['night'].map((yams, i) => {
                    yams.map((yam, index) => {
                        if (yam === response.data.data.personal_data.day_of_birth) {
                            if (index > 3) {
                                tmpYamNightBad.push({ "yam": i, "position": index })
                            } else if (index < 3) {
                                tmpYamNightGood.push({ "yam": i, "position": index })
                            }
                        }
                    })
                })

                setYamDayGood(tmpYamDayGood)
                setYamDayBad(tmpYamDayBad)
                setYamNightGood(tmpYamNightGood)
                setYamNightBad(tmpYamNightBad)

                let ratio = width / 425
                let planetPositions = response.data.data.zodiacs
                let planetSizeCal = 14 * ratio
                setPlanetSize(planetSizeCal)


                let zodiacPositionsCal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                let groupPlanet = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    []
                ]
                planetPositions.map((position, index) => {
                    let planetRasi = parseInt(position / 1800)
                    groupPlanet[planetRasi].push(index)
                })

                groupPlanet.map((gp, index) => {
                    let planetNum = gp.length
                    if (planetNum > 0) {
                        //DEBUG
                        if ([0, 3, 6, 9, 1, 4, 7, 10, 2, 5, 8, 11].includes(index)) {
                            jakkaPositions[index][planetNum - 1].map((jPosition, index2) => {
                                if (index === 0 || index === 6 || index === 1 || index === 5 || index === 7 || index === 11) {
                                    zodiacPositionsCal[gp[index2]] = { x: jPosition.x * ratio + planetSizeCal / 2, y: jPosition.y * ratio + planetSizeCal / 2 }
                                } else if (index === 3 || index === 9 || index === 4 || index === 10 || index === 2 || index === 8) {
                                    zodiacPositionsCal[gp[index2]] = { x: jPosition.y * ratio + planetSizeCal / 2, y: jPosition.x * ratio + planetSizeCal / 2 }
                                }
                            })
                        }
                    }
                })

                // ตัว test
                // zodiacPositionsCal.push({ x: 140 * ratio, y: 60 * ratio })
                // zodiacPositionsCal.push({ x: 140 * ratio + planetSizeCal / 2, y: 60 * ratio + planetSizeCal / 2 })

                setZodiacPositions(zodiacPositionsCal)
            }
        })

    }, []);

    const handleClickWallpaperMenu = () => {
        navigate("/wallpaper/shop")
    }

    const handleClickPackageMenu = () => {
        navigate("/package/shop")
    }

    const handleClickPackageBoxsetMenu = () => {
        navigate("/package_boxset/shop")
    }

    const handleClickOrderList = () => {
        navigate("/order/lists")
    }

    const handleClickWallpaperItem = (id) => {
        navigate("/wallpaper/item/" + id)
    }

    const handleClickPackageCarousel = () => {
        navigate("/package/shop/")
    }

    const handleClickHoracle = () => {
        navigate("/horacle")
    }

    const handleClickPlanYam = () => {
        let secs = ((parseInt(selectedPlanYamHourValue) * 60) + parseInt(selectedPlanYamMinuteValue)) * 60
        let dn = "day"
        let saoYam = 0

        if (secs >= 0 && secs < 21600) {
            dn = "night"
            saoYam = parseInt(secs / 5400) + 4
            // setYamCurrentTime(yamNightText[saoYam] + " น.")
        } else if (secs >= 21600 && secs < 64800) {
            dn = "day"
            saoYam = parseInt(secs / 5400) - 4
            // setYamCurrentTime(yamDayText[saoYam] + " น.")
        } else if (secs >= 64800 && secs < 86400) {
            dn = "night"
            saoYam = parseInt(secs / 5400) - 12
            // setYamCurrentTime(yamNightText[saoYam] + " น.")
        }

        let currYam = yamData[parseInt(selectedPlanYamDateValue) + 1][dn][saoYam].indexOf(personalData.day_of_birth)
        let textIntro = "วันเวลาที่คุณเลือกเพื่อ " + planYamActivities[selectedPlanYamActivityValue].activity + " นั้น"

        if (currYam === planYamActivities[selectedPlanYamActivityValue].type) {
            setPlanYamCurrentText(textIntro + "เป็นยามดีมาก เหมาะสมกับกิจกรรมที่คุณเลือกจะทำ คุณจะได้รับ " + yamMeaningText[currYam] + "มั่นใจแล้วลงมือทำ สำเร็จแน่นอน!!")
            setPlanYamCurrentCss("yamGood")
        } else if (currYam < 3) {
            setPlanYamCurrentText(textIntro + "เป็นยามดีพอใช้ ไม่ได้ดีที่สุดแต่ใช้ได้ คุณจะได้รับความสำเร็จและสมความปราถนาเป็นส่วนใหญ่ ลุยได้เลย!")
            setPlanYamCurrentCss("yamGood")
        } else if (currYam === 3) {
            setPlanYamCurrentText(textIntro + "เป็นยามกลาง ๆ ไม่ดีไม่ร้าย ผลที่ได้รับอาจจะเกิดได้ทั้งดีและร้าย")
            setPlanYamCurrentCss("yamMid")
        } else if (currYam > 3) {
            setPlanYamCurrentText(textIntro + "เป็นยามไม่ดี คุณต้องระวัง " + yamMeaningText[currYam] + " หลีกเลี่ยงได้ให้หลีกเลี่ยงไว้ก่อนจะดีกว่า")
            setPlanYamCurrentCss("yamBad")
        }
        // console.log(parseInt(selectedPlanYamDateValue) + 1, dn, saoYam, currYam, yamMeaningText[currYam])
    }

    const handleOnValueChange = (e) => {
        let id = e.target.id
        if (id === "planYamActivity") {
            setSelectedPlanYamActivityValue(e.target.value)
        } else if (id === "planYamDate") {
            setSelectedPlanYamDateValue(e.target.value)
        } else if (id === "planYamHour") {
            setSelectedPlanYamHourValue(e.target.value)
        } else if (id === "planYamMinute") {
            setSelectedPlanYamMinuteValue(e.target.value)
        } else if (id === "phone") {
            setPhone(e.target.value)
        }
    }

    const tabs = [
        {
            click: handleClickPackageBoxsetMenu,
            icon: faHatWizard,
            label: "โปรโมชั่น",
            label2: "ลดราคา"
        },
        {
            click: handleClickWallpaperMenu,
            icon: faWandMagicSparkles,
            label: "Wallpaปัง",
            label2: "เสริมดวง"
        },
        {
            click: scrollToHomeTop,
            icon: faList,
            label: "เปิดไพ่",
            label2: "ของคุณ"
        },
        {
            click: scrollToPersonalDuang,
            icon: faMagnifyingGlass,
            label: "ดูดวง",
            label2: "เท่านั้น"
        },
        {
            click: scrollToPersonalYam,
            icon: faClock,
            label: "จับยาม",
            label2: "มหามงคล"
        }
    ]

    return (
        <>
            {/* <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกเบอร์โทรศัพท์</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 16 }}>
                        กรุณากรอกเบอร์โทรศัพท์ของท่าน เพื่อความแม่นยำในการ
                        ทำนาย โดยวิชาที่ใช้ในการทำนายรวบรวมมาจากหลากหลาย
                        ศาสตร์ ครบถ้วนที่นี่ที่เดียว
                    </p>
                    <Col xs="8" style={{ padding: 5 }}>
                        <Form.Group className="mb-2">
                            <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            <Form.Control size="sm" placeholder="เบอร์โทรศัพท์" id="phone" type='number' defaultValue={phone} onChange={(handleOnValueChange)} />
                        </Form.Group>
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleClick}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* <Modal show={showPromotionModal} onHide={handleShowPromotionModal}>
                <Modal.Header>
                    <Modal.Title>สั่งจองการ์ดหอม</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src="https://ganesha168.sgp1.digitaloceanspaces.com/promotion/promotion_adhoc_1.jpeg"
                        width={'100%'}
                        onClick={handleClickPromotion}
                    />
                    <p style={{ fontWeight: 400, fontSize: 16 }}>
                        <br />
                        * เริ่มจัดส่งวันที่ 19 พฤษภาคม 2566 เป็นต้นไป
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePromotionModal}>
                        ปิด
                    </Button>
                    <Button variant="primary" onClick={handleClickPromotion}>
                        สั่งจองเลย
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* <Navbar className="header-navbar" expand="lg" sticky="top">
                <div className="logo-box">
                    <img src={LogoImg} width="12%" />
                </div>
            </Navbar> */}

            <nav className="navbar fixed-bottom navbar-light menubar" role="navigation">
                <Nav className="w-100">
                    <div className=" d-flex flex-row justify-content-around w-100">
                        {
                            tabs.map((tab, index) => {
                                if (index === 2) {
                                    return (
                                        <NavItem key={`tab-${index}`} onClick={tab.click}>
                                            <div className="logo-box">
                                                <img className="logo-om" src={LogoImg} width="80%" />
                                            </div>
                                        </NavItem>
                                    )
                                } else {
                                    return (
                                        <NavItem key={`tab-${index}`} onClick={tab.click}>
                                            <div className="row d-flex justify-content-center">
                                                <FontAwesomeIcon size="xl" icon={tab.icon} color={"#c7ac78"} />
                                                <div className="menubar-text">{tab.label}</div>
                                            </div>
                                        </NavItem>
                                    )
                                }
                            })
                        }
                    </div>
                </Nav>
            </nav>

            {user && <div className="homeBox" ref={refHomeTop}>
                <Row>
                    <Col xs="9">
                        <p className="text-name">
                            สวัสดีคุณ {user.name} {user.surname} <br />
                        </p>
                        <p className="text-detail">
                            {personalData && personalData.text_birth} <br />
                            {user.no_birth_time === 0 ? 'เวลา ' + user.birth_hour + ':' + (user.birth_minute.toString().length === 1 ? +'0' + user.birth_minute.toString() : user.birth_minute) + ' น.' : 'ไม่ทราบเวลาเกิด'} &nbsp;
                            {personalData && personalData.text_birth_place} <br />
                            <strong>{personalData && personalData.text_lukkana}</strong>
                        </p>
                        <p className="text-package-left">
                            แพคเกจปัจจุบันคงเหลืออีก: {gpackage !== null && <strong>{daysBetweenDate(new Date(), new Date(user.exp_date))} วัน</strong>} <br />
                            สิทธิ Wallpaปัง คงเหลือ: <strong>{user.wallpaper_quota === null ? 0 : user.wallpaper_quota} รูป</strong>
                        </p>
                    </Col>
                    <Col xs="3">
                        {line && <img className="profileImage" src={line.pictureUrl} width={"100%"} onClick={handleClickOrderList} />}
                    </Col>
                    <hr className="line-section" />
                    <Col xs="12" className="jakka-box">
                        {
                            zodiacPositions.map((position, index) => {
                                if (index < 10) {
                                    return (
                                        <p key={index} className="planet-position" style={{ position: 'absolute', top: position.y, left: position.x, width: planetSize, fontSize: 18 * width / 425, lineHeight: 0 }}>{planetSigns[index]}</p>
                                    )
                                } else {
                                    return (
                                        <img key={index} src={require('../assets/images/zodiacs/11.png')} style={{ position: 'absolute', top: position.y, left: position.x, width: planetSize }} />
                                    )
                                }

                            })
                        }
                        <img src={Jakka} width="100%" />
                    </Col>
                </Row>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>ภาพรวมเดือน{personalData && personalData.text_lukkana}</Accordion.Header>
                        <Accordion.Body className="accordian-body">
                            <p style={{ textAlign: 'center', marginTop: 0, fontSize: 16, color: '#2C2C2C', fontWeight: 400 }}>
                                {personalData && monthlyPrediction.summary}
                            </p>
                            <p style={{ textAlign: 'center', marginTop: 0, fontSize: 16, color: '#2C2C2C', fontWeight: 400 }}>
                                {personalData && monthlyPrediction.description}
                            </p>
                            <p style={{ textAlign: 'right', fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                ทำนายโดยอาจารย์ยุทธ โหราชำนาญฤกษ์ <br />
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Row>
                    <div className="btn-card-box">
                        <img src={BtnOpenCard} className="btn-card" onClick={handleClickHoracle} />
                    </div>
                </Row>
                <Row>
                    <Col xs="12" className="duangBox">
                        <p className="header-section">
                            สีและทิศมงคลประจำวัน <br />
                        </p>
                        <p style={{ textAlign: 'center', marginTop: 0, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                            รู้แล้วได้เปรียบ “บอกทุกทิศและความหมายสีมงคล” เฉพาะคุณ <br />
                        </p>
                        <Row>
                            {
                                taksa[today - 1].map((t, i) => {
                                    return (
                                        <Col key={i} xs="12">
                                            <Row>
                                                <Col xs="3">
                                                    {/* <Col xs="12" style={{ backgroundColor: taksaColor[taksa[today - 1][i] - 1], padding: 15, borderRadius: 6, border: (i === 2 || i === 3 || i === 6) ? '5px solid #c7ac78' : '' }}> */}
                                                    <Col xs="12" style={{ backgroundColor: taksaColor[taksa[today - 1][i] - 1], padding: 15, borderRadius: '50%' }}>
                                                        <img src={require('../assets/images/taksa_' + (i + 1) + '.png')} width="100%" />
                                                    </Col>
                                                </Col>
                                                <Col xs="9">
                                                    <p style={{ marginTop: 5, fontSize: 14, color: '#2C2C2C', fontWeight: 400 }}>
                                                        เสริม{taksaText[i]}ให้เสริมด้วยสี <strong>{taksaColorText[taksa[today - 1][i] - 1]}</strong><br /><br />
                                                        และหันหน้าไปทิศ <strong>{taksaDirection[taksa[today - 1][i] - 1]}</strong><br />
                                                    </p>
                                                    <hr className="line-section" />
                                                </Col>
                                            </Row>


                                            {/* <p style={{ textAlign: 'center', marginTop: 5, fontSize: 10, color: '#c7ac78' }}>
                                            </p> */}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <p style={{ marginTop: 0, fontSize: 16, color: '#c7ac78' }}>
                            ตัวอย่างการใช้สีและทิศมงคลประจำวัน <br />
                        </p>
                        <p style={{ marginTop: 0, fontSize: 14, color: '#2C2C2C', fontWeight: 400 }}>
                            ถ้าคุณต้องการเสริมด้านการเงินให้คุณเลือกใช้ เสื้อผ้า เครื่องประดับ หรือ Wallpaปังเสริมดวงที่เป็นสี <strong>{taksaColorText[taksa[today - 1][3] - 1]}</strong> และหันหน้าไปทิศ <strong>{taksaDirection[taksa[today - 1][3] - 1]}</strong>ในเวลาที่ต้องพูดคุยหรือเจรจา จะได้ผลสำเร็จดีนักแล<br />
                        </p>
                    </Col>

                    <Col xs="12" className="duangBox">
                        <p className="header-section">
                            เลขมงคลยาม {yamCurrentTime}
                            <br />
                        </p>
                        <p style={{ marginTop: -15, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                            ยามนี้เลขอะไรให้คุณ? คำนวณจากพื้นดวงคุณ<br />
                        </p>
                        <p style={{ textAlign: 'center', marginTop: 0, fontSize: 40, color: '#2C2C2C', fontWeight: 400 }}>
                            {yamCurrentSao[0]} - {yamCurrentSao[1]} - {yamCurrentSao[2]}<br />
                            {yamCurrentSao[6]} - {yamCurrentSao[5]} - {yamCurrentSao[4]}
                        </p>
                    </Col>
                </Row>

                {/* ADS */}

                <Row>
                    <p className="header-section" style={{ marginTop: 20 }}>เสริมดวงสุดปัง</p>
                    <br />
                    <Col xs="12" onClick={handleClickPackageBoxsetMenu}>
                        <img src={Menu3} width="100%" className="homeMenu" />
                        <p style={{ marginTop: 5, fontSize: 14, color: '#c7ac78' }}>
                            สั่งซื้อแพคเกจ Boxset โปรโมชั่น
                        </p>
                    </Col>
                    <Col xs="6" onClick={handleClickWallpaperMenu}>
                        <img src={Menu1} width="100%" className="homeMenu" />
                        <p style={{ marginTop: 5, fontSize: 14, color: '#c7ac78' }}>
                            สร้างWallpaปังคลิก !
                        </p>
                    </Col>
                    <Col xs="6" onClick={handleClickPackageMenu}>
                        <img src={Menu2} width="100%" className="homeMenu" />
                        <p style={{ marginTop: 5, fontSize: 14, color: '#c7ac78' }}>
                            ซื้อแพคเกจคลิก !
                        </p>
                    </Col>

                    <Col xs="12" style={{ marginTop: 20 }}>
                        <Carousel>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(19)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_3.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(18)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_1.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(17)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_2.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(11)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_5.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(10)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_4.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(4)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_1.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={() => handleClickWallpaperItem(5)}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_2.jpg"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    onClick={handleClickPackageCarousel}
                                    className="d-block w-100"
                                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_3.jpg"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                {/* NOTES : PACKAGE ZONE */}
                {duang && <>

                    {/* กราฟชีวิต */}
                    <Row>
                        <Col xs="12">
                            <hr className="line-section" />

                            <p ref={refPersonalDuang} style={{ marginTop: 20, fontSize: 16, color: '#c7ac78' }}>
                                “พาไปรู้จักพื้นดวงเฉพาะคุณ“ <br />ที่ไม่เหมือนใครทั้งสิ้น <br />
                                คำทำนายเฉพาะคุณ {user.name} {user.surname} เท่านั้น
                            </p>
                            <hr className="line-section" />
                        </Col>
                        <Col xs="12" className="duangBox">
                            <p className="header-section">
                                กราฟชีวิตและคำทำนายประจำวัน <br />
                                {/* กราฟชีวิตประจำวัน <br /> */}
                            </p>
                            <p style={{ marginTop: -15, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                สำหรับวันที่ {todayText}
                            </p>
                            <p style={{ textAlign: 'center', marginTop: 0, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                ดวงวันนี้ “ย่อยเป็นเปอร์เซนต์ให้ง่าย” ตรงเฉพาะคุณ<br />
                            </p>
                            <div style={{ filter: gpackage === null ? 'blur(10px) grayscale(100%)' : 'none', pointerEvents: gpackage === null ? 'none' : 'auto' }}>
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_work.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การงานวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.work < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.work}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.work} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.work}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.work}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_money.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การเงินวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.money < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.money}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.money} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.money}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.money}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_love.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            ความรักวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.love < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.love}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.love} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.love}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.love}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_health.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            สุขภาพวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.health < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.health}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.health} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.health}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.health}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_luck.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            โชคลาภวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.luck < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.luck}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.luck} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.luck}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.luck}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* ยามโสฬสกาลพม่า */}
                    <Row>
                        <Col xs="12" className="duangBox">
                            <p ref={refPersonalYam} className="header-section">
                                ยามมงคลประจำวัน
                            </p>
                            <p style={{ marginTop: -15, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                สำหรับวันที่ {todayText}
                            </p>
                            <p style={{ textAlign: 'center', marginTop: 0, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                “รู้ทุกช่วงเวลามงคล, ไม่มงคล” ละเอียดเฉพาะคุณกว่านี้ไม่มีอีกแล้ว ด้วยวิชายามโสฬสกาลพม่า <br />
                            </p>
                            <hr className="line-section" />
                            <div style={{ filter: gpackage === null ? 'blur(10px) grayscale(100%)' : 'none', pointerEvents: gpackage === null ? 'none' : 'auto' }}>
                                <p style={{ textAlign: 'center', marginTop: 0, fontSize: 18, color: '#c7ac78' }}>
                                    ยามขณะนี้ {yamCurrentTime}
                                </p>
                                <div className={yamCurrentCss}>
                                    {yamCurrentText}
                                </div>
                                <hr className="line-section" />
                                <p style={{ marginTop: 0, fontSize: 14, color: '#c7ac78' }}>
                                    ยามกลางวัน
                                </p>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#98D98E' }}>
                                        ยามมงคล
                                    </Col>
                                    <Col xs="6" style={{ backgroundColor: '#98D98E' }}>
                                        ความหมาย
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#DDFFD8', fontWeight: 400 }}>
                                        <Row>
                                            {
                                                yamDayGood.map((y, i) => {
                                                    return (
                                                        <Col key={i} xs="12">
                                                            {yamDayText[y.yam]}
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                    <Col xs="6" style={{ backgroundColor: '#DDFFD8', fontWeight: 400 }}>
                                        {
                                            yamDayGood.map((y, i) => {
                                                return (
                                                    <Col key={i} xs="12">
                                                        {yamMeaningText[y.position]}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#FF6D59' }}>
                                        ยามไม่ดี
                                    </Col>
                                    <Col xs="6" style={{ backgroundColor: '#FF6D59' }}>
                                        ความหมาย
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#FFBDB4', fontWeight: 400 }}>
                                        <Row>
                                            {
                                                yamDayBad.map((y, i) => {
                                                    return (
                                                        <Col key={i} xs="12">
                                                            {yamDayText[y.yam]}
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                    <Col xs="6" style={{ backgroundColor: '#FFBDB4', fontWeight: 400 }}>
                                        {
                                            yamDayBad.map((y, i) => {
                                                return (
                                                    <Col key={i} xs="12">
                                                        {yamMeaningText[y.position]}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>

                                </Row>

                                {/* YAM NIGHT */}
                                <p style={{ marginTop: 20, fontSize: 14, color: '#c7ac78' }}>
                                    ยามกลางคืน
                                </p>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#98D98E' }}>
                                        ยามมงคล
                                    </Col>
                                    <Col xs="6" style={{ backgroundColor: '#98D98E' }}>
                                        ความหมาย
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#DDFFD8', fontWeight: 400 }}>
                                        <Row>
                                            {
                                                yamNightGood.map((y, i) => {
                                                    return (
                                                        <Col key={i} xs="12">
                                                            {yamNightText[y.yam]}
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                    <Col xs="6" style={{ backgroundColor: '#DDFFD8', fontWeight: 400 }}>
                                        {
                                            yamNightGood.map((y, i) => {
                                                return (
                                                    <Col key={i} xs="12">
                                                        {yamMeaningText[y.position]}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#FF6D59' }}>
                                        ยามไม่ดี
                                    </Col>
                                    <Col xs="6" style={{ backgroundColor: '#FF6D59' }}>
                                        ความหมาย
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6" style={{ backgroundColor: '#FFBDB4', fontWeight: 400 }}>
                                        <Row>
                                            {
                                                yamNightBad.map((y, i) => {
                                                    return (
                                                        <Col key={i} xs="12">
                                                            {yamNightText[y.yam]}
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                    <Col xs="6" style={{ backgroundColor: '#FFBDB4', fontWeight: 400 }}>
                                        {
                                            yamNightBad.map((y, i) => {
                                                return (
                                                    <Col key={i} xs="12">
                                                        {yamMeaningText[y.position]}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <hr style={{ marginTop: 20, border: '1px solid #c7ac78' }} />
                                        <p className="header-section">
                                            วางแผนยามล่วงหน้า
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <Form.Group className="mb-2">
                                            <Form.Label style={{ marginTop: 0, fontSize: 14, color: '#c7ac78' }}>กิจกรรมที่จะวางแผน</Form.Label>
                                            <Form.Select onChange={(handleOnValueChange)} defaultValue={selectedPlanYamActivityValue} id="planYamActivity" size="sm" disabled={gpackage === null ? true : false}>
                                                {
                                                    planYamActivities.map((activity) => {
                                                        return <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12">
                                        <Form.Group className="mb-2">
                                            <Form.Label style={{ marginTop: 0, fontSize: 14, color: '#c7ac78' }}>วันที่ต้องการ (ล่วงหน้าได้ไม่เกิน 30 วัน)</Form.Label>
                                            <Form.Select onChange={(handleOnValueChange)} defaultValue={selectedPlanYamDateValue} id="planYamDate" size="sm" disabled={gpackage === null ? true : false}>
                                                {
                                                    planYamDateObjects.map((planYam) => {
                                                        return <option key={planYam.id} value={planYam.day}>{planYam.text}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4">
                                        <Form.Group className="mb-2">
                                            <Form.Label style={{ marginTop: 0, fontSize: 14, color: '#c7ac78' }}>เวลา</Form.Label>
                                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={selectedPlanYamHourValue} id="planYamHour" disabled={gpackage === null ? true : false}>
                                                {
                                                    Array.from(Array(24), (e, i) => {
                                                        return <option key={i} value={i}>{padLeadingZeros(i, 2)}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4">
                                        <Form.Group className="mb-2">
                                            <Form.Label><br /></Form.Label>
                                            <Form.Select size="sm" onChange={(handleOnValueChange)} defaultValue={selectedPlanYamMinuteValue} id="planYamMinute" disabled={gpackage === null ? true : false}>
                                                {
                                                    Array.from(Array(60), (e, i) => {
                                                        return <option key={i} value={i}>{padLeadingZeros(i, 2)}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" style={{ marginTop: 20, textAlign: 'center' }}>
                                        <Button variant="primary" onClick={handleClickPlanYam}>
                                            คำนวณยามล่วงหน้า
                                        </Button>
                                    </Col>
                                    <Col xs="12">
                                        <hr style={{ marginTop: 20, border: '1px solid #c7ac78' }} />
                                        <p style={{ marginTop: -20, fontSize: 16, color: '#c7ac78', fontWeight: 400 }}>
                                            <br />ผลการคำนวณ<br />
                                        </p>
                                        <div className={planYamCurrentCss}>
                                            {planYamCurrentText}
                                        </div>
                                    </Col>
                                    <hr style={{ marginTop: 20, border: '1px solid #c7ac78' }} />
                                </Row>
                            </div>
                            <p style={{ marginTop: -20, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                <br />วิชามหายามโสฬสกาลพม่า<br />
                                โดยพระอาจารย์มหาลาภ สัจจัญญูภิกขุ<br />
                                วัดป่าเจริญธรรมศิลาวาส อ.เชียงดาว จ.เชียงใหม่
                            </p>
                        </Col>
                    </Row>

                    {/* คำทำนายตามดวงดาว */}
                    <Row>
                        <Col xs="12" className="duangBox">
                            <p className="header-section">
                                คำทำนายตามดวงดาว <br />
                            </p>
                            <p style={{ textAlign: 'center', marginTop: 0, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                เตรียมรู้ “ทุกช่วงการย้ายของดวงดาว” เราจะบอกทั้งหมดเฉพาะคุณ<br />
                            </p>
                            <div style={{ filter: gpackage === null ? 'blur(10px) grayscale(100%)' : 'none', pointerEvents: gpackage === null ? 'none' : 'auto' }}>
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="3">
                                        <img src={require('../assets/images/icon_work.png')} width="100%" />
                                    </Col>
                                    <Col xs="9">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การงาน
                                        </p>
                                        <p style={{ marginTop: -10, fontSize: 14, color: duang.prediction.work.khen < 2 ? '#FF6D59' : '#98D98E', fontWeight: 400 }}>
                                            เกณฑ์{khen[duang.prediction.work.khen]} ถึง {duang.prediction.work.to}
                                        </p>
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.prediction.work.description}
                                            {/* ดาวอาทิตย์ (๑) ดาวเจ้าเรือนกัมมะหรือการงานของคุณศิวกิตติ์ไปอยู่ในเรือนปัตนิ อันหมายถึง คนรัก คู่ครอง หุ้นส่วน แปลความได้ว่าช่วงนี้ มีโอกาสได้ร่่วมงานกับคู่ครองหรือหุ้นส่วน และเป็นไปในจังหวะทิศทางที่ดี เพราะดาวอาทิตย์ได้ตำแหน่งราชาโชค */}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="3">
                                        <img src={require('../assets/images/icon_money.png')} width="100%" />
                                    </Col>
                                    <Col xs="9">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การเงิน
                                        </p>
                                        <p style={{ marginTop: -10, fontSize: 14, color: duang.prediction.money.khen < 2 ? '#FF6D59' : '#98D98E', fontWeight: 400 }}>
                                            เกณฑ์{khen[duang.prediction.money.khen]} ถึง {duang.prediction.money.to}
                                        </p>
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.prediction.money.description}
                                            {/* ดาวเจ้าเรือนกดุมภะหรือการเงินของคุณศิวกิตติ์คือดาวพฤหัสบดี (๕) ไปอยู่ในเรือนพันธุ อันหมายถึง บ้านที่อยู่อาศัยหรือครอบครัว ได้ตำแหน่งเกษตร และยังได้ปทุมเกณฑ์ เป็นไปในทิศทางที่ดีมาก แปลความได้ว่า มีโอกาสได้ทรัพย์สินอสังหาริมทรัพย์ใหม่ ๆ รวมถึงได้ผลประโยชน์จากครอบครัว */}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="3">
                                        <img src={require('../assets/images/icon_love.png')} width="100%" />
                                    </Col>
                                    <Col xs="9">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            ความรัก
                                        </p>
                                        <p style={{ marginTop: -10, fontSize: 14, color: duang.prediction.love.khen < 2 ? '#FF6D59' : '#98D98E', fontWeight: 400 }}>
                                            เกณฑ์{khen[duang.prediction.love.khen]} ถึง {duang.prediction.love.to}
                                        </p>
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.prediction.love.description}
                                            {/* ดาวศุกร์ (๖) ดาวเจ้าเรือนปัตนิหรือ คนรัก คู่ครอง หุ้นส่วน ไปอยู่ในเรือนอริ ที่แปลว่า ปัญหาหรืออุปสรรค แปลว่าในช่วงนี้ ความรักความสัมพันธ์อาจจะมีปัญหาได้ */}
                                        </p>
                                    </Col>
                                </Row>

                                {/* <Row style={{ marginTop: 20 }}>
                            <Col xs="3">
                                <img src={require('../assets/images/icon_health.png')} width="100%" />
                            </Col>
                            <Col xs="9">
                                <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                    สุขภาพ
                                </p>
                                <p style={{ marginTop: -10, fontSize: 16, color: '#FF6D59', fontWeight: 400 }}>
                                    เกณฑ์ต้องระวังถึง 9 ส.ค. 2565
                                </p>
                                <hr className="line-section" />
                                <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                    {duang.prediction.work.description}
                                    ดาวอังคาร (๓) ดาวอายุ ซึ่งแปลว่าสุขภาพ การดำรงชีพ ของคุณ ได้ตำแหน่งประ อ่อนกำลัง แปลว่า สุขภาพคุณในช่วงเวลานี้นั้นต้องระวังการเจ็บป่วย
                                </p>
                            </Col>
                        </Row> */}
                            </div>
                        </Col>
                    </Row>
                </>}

                <Row style={{ marginTop: 100 }}>

                </Row>
            </div >}
        </>
    )
}

export default Home;
