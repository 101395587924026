import React from "react";
import { useEffect, useState } from 'react';
import { useLiff } from 'react-liff';
import { Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

import * as userAction from './redux/actions/user-action';
import * as userHttpServices from './services/user-http-services';

import LogoImg from './assets/images/om168-gold-logo.png'

import './scss/App.scss';

function App() {

  const { error, liff, isLoggedIn, ready } = useLiff();

  const dispatch = useDispatch();
  const lineUserLogin = lineAccessToken => dispatch(userAction.lineUserLogin(lineAccessToken))
  const saveUserData = userData => dispatch(userAction.saveUserData(userData))
  const saveUserLineData = userLineData => dispatch(userAction.saveUserLineData(userLineData))

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // console.log('isLoggedIn', isLoggedIn)

    if (!isLoggedIn) return;

    (async () => {

      lineUserLogin(liff.getAccessToken())
      // console.log("token 1", liff.getAccessToken())

      userHttpServices.lineLogin(liff.getAccessToken())
        .then((response) => {
          let loginData = response.data
          // console.log('login', loginData)

          if (loginData.data === null) {
            navigate("/register")

          } else {
            saveUserData(loginData.data)
            saveUserLineData(loginData.line_data)

            if (searchParams.get('redirect') === null) {
              navigate("/home")
            } else if (searchParams.get('redirect') === "wallpaper") {
              navigate("/wallpaper/shop")
            } else if (searchParams.get('redirect') === "package") {
              navigate("/package/shop")
            } else if (searchParams.get('redirect') === "boxset") {
              navigate("/package_boxset/shop")
            } else if (searchParams.get('redirect') === "horacle") {
              navigate("/horacle")
            } else if (searchParams.get('redirect') === "shop") {
              navigate("/shop")
            }
          }
        }).catch(err => {
          // console.log("error", err)
        })


    })();
  }, [liff, isLoggedIn]);

  const showLoginButton = () => {
    if (error) return <p>Something is wrong.</p>;
    if (!ready) return <p>Loading...</p>;

    if (!isLoggedIn) {
      return (
        <>
          <br />
          <Button variant="primary" onClick={liff.login}>
            เข้าสู่ระบบ
          </Button>
        </>

      );
    }

    // return (
    //   <>
    //     <br />
    //     <Button variant="primary" onClick={liff.logout}>
    //       Logout
    //     </Button>
    //   </>
    // );
  };

  return (
    <div className="loginBox">
      <img src={LogoImg} className="loginLogo" />
      {showLoginButton()}
    </div>
  )
}

export default App;
