import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const getAllWallpaper = () => {
    return axios.get(config.BASE_API_URL + '/wallpapers')
};

export const getWallpaperById = (wid) => {
    return axios.get(config.BASE_API_URL + '/wallpaper/' + wid)
};

export const getWallpaperWithCategories = (wid) => {
    return axios.get(config.BASE_API_URL +  '/categories/wallpapers_categories')
};