import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import GNavbar from '../../components/navbar';

import * as userHttpServices from '../../services/user-http-services';
import * as packageHttpServices from "../../services/package-http-services";

function PackageShop() {

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();

    const [userPackage, setUserPackage] = useState(null)
    const [packages, setPackages] = useState([])
    const [selectedPackageId, setSelectedPackageId] = useState(0)
    const [selectedPackageName, setSelectedPackageName] = useState(0)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = (pid, name) => {
        setSelectedPackageId(pid)
        setSelectedPackageName(name)
        setShowConfirmModal(true)
    }

    const handleClickOrderPackage = () => {
        navigate('/order/confirm', {
            state: {
                "type": 1,
                "package_id": selectedPackageId
            }
        })
    }


    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                setUserPackage(response.data.data.package)
            }
        })

        packageHttpServices.getAllPackages()
            .then((response) => {
                // console.log(response.data)
                let packages = response.data.data
                setPackages(packages)
            })

    }, []);

    return (
        <>
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันสร้างWallpaปัง</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 16 }}>
                        ยืนยันการเลือกแพคเกจ <br /><strong>{selectedPackageName}</strong> <br />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClickOrderPackage}>
                        ยืนยันการเลือกแพคเกจ
                    </Button>
                </Modal.Footer>
            </Modal>
            <GNavbar title="เลือกแพคเกจดูดวงที่คุณต้องการสั่งซื้อ" navigation={"/shop"} />
            <div className="wallpaperBg">
                <div className="wallpaperBox">
                    <Row>
                        <h1>เลือกซื้อแพคเกจดูดวง</h1>
                        <Col xs="12">
                            <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                เจาะตรงทุกดวงชะตา วันเปลี่ยนดวงเปลี่ยน เราทำนายใหม่เสริฟถึงมือคุณทุกวัน
                                ทั้ง <strong>“ดูดวงตามดาว กราฟชีวิต สีและทิศมงคล ยามมงคลประจำวัน”</strong>
                                ด้วยวิชาจากบรมอาจาร์ยชั้นครูโหร ที่ผ่านประสบการณ์ดูดวงยาวนานหลากหลายท่าน ไม่ว่าจะเป็น โหราศาสตร์ไทย พม่า หรือ จีน

                                ล็อคทุกความสำเร็จแบบนี้ให้เฉพาะคุณเท่านั้นทุกวัน ไม่ปังให้รู้ไป!
                                เริ่มสตาร์ทความปังเพียงเดือนละ 250 บาท
                            </p>
                        </Col>
                        {userPackage !== null && <Col xs="12">
                            <p style={{ marginTop: 0, fontSize: 16, color: 'green', fontWeight: 400 }}>
                                {/* ** คุณมีแพกเกจที่ได้ทำการสั่งซื้อไว้แล้ว ไม่สามารถทำการสั่งซื้อซ้ำได้ คุณจะสั่งซื้อเพื่อต่ออายุได้อีกครั้งก่อนหมดอายุ 7 วัน ** */}
                                ** คุณมีแพกเกจที่ได้ทำการสั่งซื้อไว้แล้ว สามารถสั่งซื้อวันเพิ่มเติมได้ เพื่อไม่ให้พลาดดูคำทำนายของคุณ **
                            </p>
                        </Col>}
                    </Row>

                    {
                        packages.map((gpackage) => {
                            return (
                                <Row key={gpackage.package_id}>
                                    <Col xs="12" className="wallpaperShortDescription">
                                        <Row>
                                            <Col xs="3">
                                                <img src={gpackage.image} width="100%" />
                                            </Col>
                                            <Col xs="9">
                                                {gpackage.package_name}<br />
                                                ราคา {gpackage.price} บาท<br /> (จากราคา {250 * gpackage.month} บาท)
                                                <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>{gpackage.description}</p>
                                                {/* <Button variant="primary" onClick={() => handleShowConfirmModal(gpackage.package_id, gpackage.package_name)} disabled={userPackage !== null}> */}
                                                <Button variant="primary" onClick={() => handleShowConfirmModal(gpackage.package_id, gpackage.package_name)}>
                                                    สมัครแพคเกจ คลิก!
                                                    {/* {userPackage === null ? "สั่งซื้อคลิก !" : "คุณมีแพกเกจอยู่แล้ว"} */}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default PackageShop;