import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import * as userHttpServices from '../services/user-http-services';
import GNavbar from "../components/navbar";

import Menu1 from '../assets/images/menu_1_v4.jpeg'
import Menu3 from '../assets/images/menu_2_v4.jpeg'
import TabBar from "../components/tab-bar";
import SectionHeaderLine from "../components/section-header-line";
import ProfileInformation from "../components/profile-information";

function Shop() {

    const { error, liff, isLoggedIn, ready } = useLiff()
    let navigate = useNavigate();

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    const daysBetweenDate = (date1, date2) => {
        let difference = date2.getTime() - date1.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    const handleClickWallpaperMenu = () => {
        navigate("/wallpaper/shop")
    }

    const handleClickPackageBoxsetMenu = () => {
        navigate("/package_boxset/shop")
    }

    const handleClickPackageBoxsetRahu = () => {
        navigate('/order/confirm', {
            state: {
                "type": 2,
                "boxset_id": 6
            }
        })
    }

    const handleClickOrderList = () => {
        navigate("/order/lists")
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
            }
        })
    }, []);

    return (
        <>
            <TabBar selectedIndex={4} />
            {user && <div className="homeBox">
                {personalData && <ProfileInformation user={user} personalData={personalData} line={line} />}
                <Row>
                    <hr className="line-section-v4" />
                    <p className="text-package-left">
                        วันคงเหลืออีก: {gpackage !== null && <strong>{daysBetweenDate(new Date(), new Date(user.exp_date))} วัน</strong>} {' | '}
                        สิทธิ Wallpaปัง คงเหลือ: <strong>{user.wallpaper_quota === null ? 0 : user.wallpaper_quota} รูป</strong>
                    </p>
                    {/* <SectionHeaderLine header={'รายการเสริมดวงราหูย้าย'} />
                    <br />
                    <Col xs="12" onClick={handleClickPackageBoxsetRahu}>
                        <img src={"https://ganesha168.sgp1.digitaloceanspaces.com/promotion/promotion-boxset-rahu2023.jpeg"} width="100%" className="homeMenu" />
                        <p className="text-package-desc">
                            จุดเทียน “ราหูหนุนดวง”<br />
                            ดูดวงฟรี! 3 เดือน + Wallpaปัง 3 ภาพ <br />
                            ทำพิธีตามศาสตร์ล้านนาโบราณ<br />
                            โดยพระมหากิตติพงษ์ สัจจัญญู (มหาลาภ)<br />
                            พร้อมปลุกเสกยันต์ที่มีชื่อและดวงชะตาคุณโดยเฉพาะ
                        </p>
                    </Col> */}
                    <SectionHeaderLine header={'รายการเสริมดวง'} />
                    <Col xs="12" onClick={handleClickPackageBoxsetMenu}>
                        <img src={Menu3} width="100%" className="homeMenu" />
                        <p className="text-package-desc">
                            สั่งซื้อแพคเกจ Boxset โปรโมชั่น
                        </p>
                    </Col>
                    <Col xs="12" onClick={handleClickWallpaperMenu}>
                        <img src={Menu1} width="100%" className="homeMenu" />
                        <p className="text-package-desc">
                            สร้างWallpaปังคลิก !
                        </p>
                    </Col>
                    <Button variant="primary" onClick={handleClickOrderList}>
                        รายการสั่งซื้อของคุณ
                    </Button>
                </Row>
            </div>}
            <Row style={{ marginTop: 100 }}>

            </Row>
        </>
    )
}

export default Shop;
