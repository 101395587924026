import { useLayoutEffect, useRef, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { getfullDateFormatDayJs, thaiFullDateFormatDayJs } from "../utils";
import dayjs from "dayjs";

const PlanetCard = ({ planet }) => {

    const refLine = useRef(null);
    const [lineWidth, setLineWidth] = useState(0)
    const motions = ['', '❮ ถอยหลัง (พักร์)', '~ หยุดนิ่ง (มนฑ์) ~', 'เดินเร็ว (เสริด) ❯']

    useLayoutEffect(() => {
        // console.log(refLine.current.offsetWidth)
        setLineWidth(refLine.current.offsetWidth)
    }, []);

    return (
        <Row className="planet-card-box">
            <Col xs="12" className="planet-card-header">
                {(planet.planet_num !== 0 && planet.planet_num !== 9) ?
                    "เจ้าเรือน" + planet.planet_of_house_name + " (" + planet.planet_house_meaning + ") คือดาว" + planet.planet_name :
                    "ดาว" + planet.planet_name}

            </Col>
            <Col xs="12" className="planet-card-detail">
                <Row>
                    <Col xs="12">
                        <p className="planet-card-rasi-name">{planet.planet_name}สถิต {planet.planet_current_rasi_name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <p className="planet-card-rasi-name">{planet.planet_before_rasi_name}</p>
                        <p className="planet-card-rasi-desc">{
                            dayjs(planet.planet_move.date_move).isBefore(dayjs(planet.planet_move.date_finish_motion)) ?
                                <>ย้ายเข้า {getfullDateFormatDayJs(dayjs(planet.planet_move.date_move))}</> : ''
                        }</p>
                    </Col>
                    <Col xs="6" className="planet-card-wrap">
                        <div className="planet-card-line" ref={refLine}>
                            <div className="planet-card-dot" style={{ left: ((lineWidth * ((planet.planet_num === 8 || planet.planet_num === 9) ? 30 - planet.planet_position_rasi.degree : planet.planet_position_rasi.degree)) / 30) }}>
                                <img src={require('../assets/images/planets/p0' + planet.planet_num + '.png')} width="100%" />
                            </div>
                            <p className="planet-card-planet-motion">
                                {motions[planet.planet_move.current_orbit]} <br />
                                {planet.planet_move.current_orbit !== 0 ? "ถึงวันที่ " + getfullDateFormatDayJs(dayjs(planet.planet_move.date_finish_motion)) : ""}
                            </p>
                        </div>
                    </Col>
                    <Col xs="3">
                        <p className="planet-card-rasi-name">{planet.planet_after_rasi_name}</p>
                        <p className="planet-card-rasi-desc">{
                            (planet.planet_move.current_orbit !== 1 || dayjs(planet.planet_move.date_move).isAfter(dayjs(planet.planet_move.date_finish_motion))) ?
                                <>ย้ายเข้า {getfullDateFormatDayJs(dayjs(planet.planet_move.date_move))}</> : ''
                        }
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="planet-card-planet-desc">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>อ่านคำทำนาย{planet.planet_name} สถิติเรือน{planet.planet_current_house_name}</Accordion.Header>
                                <Accordion.Body className="accordian-body">
                                    <strong>คำทำนาย: </strong>
                                    <div dangerouslySetInnerHTML={{ __html: planet.prediction }} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PlanetCard;