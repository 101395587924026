import { Accordion, Col, Row } from "react-bootstrap";
import { getSevenDaysObject, getTaksabyDayJs } from "../utils";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DailyColors = ({ date }) => {

    const [taksa, setTaksa] = useState([])

    useEffect(() => {
        setTaksa(getTaksabyDayJs(date))
    }, [date]);

    return (
        <div>
            {
                taksa.map((taksaObject, index) => {
                    return (
                        <Row key={"daily_taksa_color_" + index} className="daily-text">
                            <Col xs="6">
                                <p className={index === 0 ? "daily-colors-meaning-red" : "daily-colors-meaning"}>
                                    <FontAwesomeIcon icon={taksaObject['icon']} />{' '}
                                    {taksaObject['meaning']} <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;(ทิศ{taksaObject['direction']})
                                </p>
                            </Col>
                            <Col xs="2">
                                <div className="daily-colors-color" style={{ backgroundColor: taksaObject['color_1'] }} />
                            </Col>
                            <Col xs="2">
                                <div className="daily-colors-color" style={{ backgroundColor: taksaObject['color_2'] }} />
                            </Col>
                            <Col xs="2">
                                <div className="daily-colors-color" style={{ backgroundColor: taksaObject['color_3'] }} />
                            </Col>
                        </Row>
                    )
                })
            }
            <br />
            {taksa[2] && <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>ตัวอย่างการใช้สีมงคลประจำวัน</Accordion.Header>
                    <Accordion.Body className="accordian-body">
                        ถ้าคุณต้องการเสริมด้านการเงินให้คุณเลือกใช้ เสื้อผ้า เครื่องประดับ หรือ Wallpaปังเสริมดวงที่เป็นสี <strong>{taksa[2]['color_text']}</strong> จะช่วยให้คุณได้ผลสำเร็จดีนักแล<br />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>}
        </div>
    )
}

export default DailyColors;