import { Col, Row } from 'react-bootstrap';
import JakkaImage from '../assets/images/jakka_gold-new.png'
import { useEffect, useState } from 'react';

const Jakka = ({ planetPositions }) => {

    const [zodiacPositions, setZodiacPositions] = useState(null)
    const [planetSize, setPlanetSize] = useState(0)

    const { innerWidth: width, innerHeight: height } = window;
    const jakkaPositions = [
        [
            // เมษ
            [{ x: 192.5, y: 85 }],
            [{ x: 192.5, y: 85 }, { x: 192.5, y: 110 }],
            [{ x: 192.5, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 60 }, { x: 205, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }, { x: 180, y: 135 }, { x: 205, y: 135 }],
        ],
        [
            // พฤษภ
            [{ x: 127.5, y: 85 }],
            [{ x: 127.5, y: 85 }, { x: 140, y: 110 }],
            [{ x: 115, y: 85 }, { x: 140, y: 85 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }, { x: 140, y: 135 }],
        ],
        [
            // มิถุน
            [{ x: 127.5, y: 85 }],
            [{ x: 127.5, y: 85 }, { x: 140, y: 110 }],
            [{ x: 115, y: 85 }, { x: 140, y: 85 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }],
            [{ x: 140, y: 60 }, { x: 115, y: 85 }, { x: 127.5, y: 85 }, { x: 140, y: 85 }, { x: 127.5, y: 110 }, { x: 140, y: 110 }, { x: 140, y: 135 }],
        ],
        [
            // กรกฏ
            [{ x: 192.5, y: 85 }],
            [{ x: 192.5, y: 85 }, { x: 192.5, y: 110 }],
            [{ x: 192.5, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 180, y: 60 }, { x: 205, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }],
            [{ x: 192.5, y: 60 }, { x: 180, y: 85 }, { x: 205, y: 85 }, { x: 180, y: 110 }, { x: 205, y: 110 }, { x: 180, y: 135 }, { x: 205, y: 135 }],
        ],
        [
            // สิงห์
            [{ x: 257.5, y: 85 }],
            [{ x: 257.5, y: 85 }, { x: 245, y: 110 }],
            [{ x: 270, y: 85 }, { x: 245, y: 85 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }, { x: 245, y: 135 }],
        ],
        [
            // กันย์
            [{ x: 127.5, y: 300 }],
            [{ x: 127.5, y: 300 }, { x: 140, y: 275 }],
            [{ x: 115, y: 300 }, { x: 140, y: 300 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }, { x: 140, y: 250 }],
        ],
        [
            // ตุลย์
            [{ x: 192.5, y: 300 }],
            [{ x: 192.5, y: 300 }, { x: 192.5, y: 275 }],
            [{ x: 192.5, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 325 }, { x: 205, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }, { x: 180, y: 250 }, { x: 205, y: 250 }],
        ],
        [
            // พิจิก
            [{ x: 257.5, y: 300 }],
            [{ x: 257.5, y: 300 }, { x: 245, y: 275 }],
            [{ x: 270, y: 300 }, { x: 245, y: 300 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }, { x: 245, y: 250 }],
        ],
        [
            // ธนู
            [{ x: 257.5, y: 300 }],
            [{ x: 257.5, y: 300 }, { x: 245, y: 275 }],
            [{ x: 270, y: 300 }, { x: 245, y: 300 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }],
            [{ x: 245, y: 325 }, { x: 270, y: 300 }, { x: 257.5, y: 300 }, { x: 245, y: 300 }, { x: 257.5, y: 275 }, { x: 245, y: 275 }, { x: 245, y: 250 }],
        ],
        [
            // มกร
            [{ x: 192.5, y: 300 }],
            [{ x: 192.5, y: 300 }, { x: 192.5, y: 275 }],
            [{ x: 192.5, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 180, y: 325 }, { x: 205, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }],
            [{ x: 192.5, y: 325 }, { x: 180, y: 300 }, { x: 205, y: 300 }, { x: 180, y: 275 }, { x: 205, y: 275 }, { x: 180, y: 250 }, { x: 205, y: 250 }],

        ],
        [
            // กุมภ์
            [{ x: 127.5, y: 300 }],
            [{ x: 127.5, y: 300 }, { x: 140, y: 275 }],
            [{ x: 115, y: 300 }, { x: 140, y: 300 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }],
            [{ x: 140, y: 325 }, { x: 115, y: 300 }, { x: 127.5, y: 300 }, { x: 140, y: 300 }, { x: 127.5, y: 275 }, { x: 140, y: 275 }, { x: 140, y: 250 }],
        ],
        [
            //มีน
            [{ x: 257.5, y: 85 }],
            [{ x: 257.5, y: 85 }, { x: 245, y: 110 }],
            [{ x: 270, y: 85 }, { x: 245, y: 85 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }],
            [{ x: 245, y: 60 }, { x: 270, y: 85 }, { x: 257.5, y: 85 }, { x: 245, y: 85 }, { x: 257.5, y: 110 }, { x: 245, y: 110 }, { x: 245, y: 135 }],
        ],
    ]

    const planetSigns = [
        "๐",
        "๑",
        "๒",
        "๓",
        "๔",
        "๕",
        "๖",
        "๗",
        "๘",
        "๙",
        "ล",
    ]

    useEffect(() => {
        let ratio = width / 425
        let planetSizeCal = 14 * ratio
        setPlanetSize(planetSizeCal)

        let zodiacPositionsCal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        let groupPlanet = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ]
        planetPositions.map((position, index) => {
            let planetRasi = parseInt(position / 1800)
            groupPlanet[planetRasi].push(index)
        })

        groupPlanet.map((gp, index) => {
            let planetNum = gp.length
            if (planetNum > 0) {
                //DEBUG
                if ([0, 3, 6, 9, 1, 4, 7, 10, 2, 5, 8, 11].includes(index)) {
                    jakkaPositions[index][planetNum - 1].map((jPosition, index2) => {
                        if (index === 0 || index === 6 || index === 1 || index === 5 || index === 7 || index === 11) {
                            zodiacPositionsCal[gp[index2]] = { x: jPosition.x * ratio + planetSizeCal / 2, y: jPosition.y * ratio + planetSizeCal / 2 }
                        } else if (index === 3 || index === 9 || index === 4 || index === 10 || index === 2 || index === 8) {
                            zodiacPositionsCal[gp[index2]] = { x: jPosition.y * ratio + planetSizeCal / 2, y: jPosition.x * ratio + planetSizeCal / 2 }
                        }
                    })
                }
            }
        })

        setZodiacPositions(zodiacPositionsCal)
    }, [])


    return (
        <Row>
            <Col xs="12" className="jakka-box">
                {
                    zodiacPositions && zodiacPositions.map((position, index) => {
                        if (index < 10) {
                            return (
                                <p key={index} className="planet-position" style={{ position: 'absolute', top: position.y, left: position.x, width: planetSize, fontSize: 18 * width / 425, lineHeight: 0 }}>{planetSigns[index]}</p>
                            )
                        } else {
                            return (
                                <img key={index} src={require('../assets/images/zodiacs/11.png')} style={{ position: 'absolute', top: position.y, left: position.x, width: planetSize }} />
                            )
                        }

                    })
                }
                <img src={JakkaImage} width="100%" />
            </Col>
        </Row>
    )
}

export default Jakka;