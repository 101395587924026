import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard, faMagnifyingGlass, faCalendarDays, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Nav, NavItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// import LogoImg from '../assets/images/om168-gold-logo-circle.png'

const TabBar = ({ selectedIndex }) => {

    let navigate = useNavigate();

    const handleClickLuck = () => {
        navigate("/luck")
    }

    const handleClickPlanner = () => {
        navigate("/planner")
    }

    const handleClickOm = () => {
        navigate("/home")
    }

    const handleClickHoro = () => {
        navigate("/horo")
    }

    const handleClickShop = () => {
        navigate("/shop")
    }

    const tabs = [
        {
            click: handleClickLuck,
            icon: faHatWizard,
            label: "LUCK",
        },
        {
            click: handleClickPlanner,
            icon: faCalendarDays,
            label: "PLAN",
        },
        {
            click: handleClickOm,
            icon: null,
            label: "OM",
        },
        {
            click: handleClickHoro,
            icon: faMagnifyingGlass,
            label: "HORO",
        },
        {
            click: handleClickShop,
            icon: faShoppingCart,
            label: "SHOP",
        }
    ]

    return (
        <>
            <nav className="navbar fixed-bottom navbar-light menubar" role="navigation">
                <Nav className="w-100">
                    <div className=" d-flex flex-row justify-content-around w-100">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <NavItem key={`tab-${index}`} onClick={tab.click}>
                                        <div className="logo-box">
                                            <img className="logo-om" src={require('../assets/menu_icons/icon-' + (index + 1) + (selectedIndex === index ? '-active' : '') + '.png')} width="80%" />
                                        </div>
                                    </NavItem>
                                )
                                // if (index === 2) {
                                //     return (
                                //         <NavItem key={`tab-${index}`} onClick={tab.click}>
                                //             <div className="logo-box">
                                //                 <img className="logo-om" src={LogoImg} width="80%" />
                                //             </div>
                                //         </NavItem>
                                //     )
                                // } else {
                                //     return (
                                //         <NavItem key={`tab-${index}`} onClick={tab.click}>
                                //             <div className="row d-flex justify-content-center">
                                //                 <FontAwesomeIcon size="xl" icon={tab.icon} color={"#c7ac78"} />
                                //                 <div className="menubar-text">{tab.label}</div>
                                //             </div>
                                //         </NavItem>
                                //     )
                                // }
                            })
                        }
                    </div>
                </Nav>
            </nav>
        </>
    )
}

export default TabBar;