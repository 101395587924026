import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import GNavbar from '../../components/navbar';

import * as userHttpServices from '../../services/user-http-services';
import * as packageHttpServices from "../../services/package-http-services";

function PackageBoxsetShop() {

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();

    const [userPackage, setUserPackage] = useState(null)
    const [packages, setPackages] = useState([])
    const [selectedPackageId, setSelectedPackageId] = useState(0)
    const [selectedPackageName, setSelectedPackageName] = useState(0)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = (pid, name) => {
        setSelectedPackageId(pid)
        setSelectedPackageName(name)
        setShowConfirmModal(true)
    }

    const handleClickOrderPackage = () => {
        navigate('/order/confirm', {
            state: {
                "type": 2,
                "boxset_id": selectedPackageId
            }
        })
    }


    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                setUserPackage(response.data.data.package)
            }
        })

        packageHttpServices.getAllPackageBoxsets()
            .then((response) => {
                // console.log(response.data)
                let packages = response.data.data
                setPackages(packages)
            })

    }, []);

    return (
        <>
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันสร้าง Boxset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 14 }}>
                        ยืนยันการเลือก Boxset  <br /><strong>{selectedPackageName}</strong> <br />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClickOrderPackage}>
                        ยืนยันการเลือก Boxset
                    </Button>
                </Modal.Footer>
            </Modal>
            <GNavbar title="เลือก Boxset ดูดวงที่คุณต้องการสั่งซื้อ" navigation={"/shop"} />
            <div className="wallpaperBg">
                <div className="wallpaperBox">
                    <Row>
                        <h1>เลือกซื้อ Boxset ดูดวง</h1>
                        <Col xs="12">
                            <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                คุ้มกว่านี้ไม่มีอีกแล้ว! Boxset สุดคุ้มสำหรับสายมูตัวจริง! <strong>"แพคเกจดูดวงที่มาพร้อมกับวอลเปเปอร์เสริมดวง" </strong>
                                ให้คุณเลือกสร้างได้เต็มที่ เปลี่ยนได้ทุกสถานการณ์
                            </p>
                        </Col>
                    </Row>

                    {
                        packages.map((gpackage) => {
                            return (
                                <Row key={gpackage.boxset_id}>
                                    <Col xs="12" className="wallpaperShortDescription">
                                        <Row>
                                            <Col xs="5">
                                                <img src={gpackage.image} width="100%" />
                                            </Col>
                                            <Col xs="7">
                                                <p style={{ marginTop: 10, fontSize: 18, fontWeight: 400 }}>{gpackage.boxset_name}</p>
                                                ราคา {gpackage.price} บาท<br /> (จากราคา {250 * gpackage.month + (199 * gpackage.wallpaper_quota) + (gpackage.boxset_id === 6 ? 500 : 0)} บาท)
                                                <p style={{ marginTop: 10, fontSize: 14, fontWeight: 400 }}>{gpackage.description}</p>
                                                {/* <Button variant="primary" onClick={() => handleShowConfirmModal(gpackage.package_id, gpackage.package_name)} disabled={userPackage !== null}> */}
                                                <Button variant="primary" onClick={() => handleShowConfirmModal(gpackage.boxset_id, gpackage.package_name)}>
                                                    สมัคร Boxset คลิก!
                                                    {/* {userPackage === null ? "สั่งซื้อคลิก !" : "คุณมีแพกเกจอยู่แล้ว"} */}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default PackageBoxsetShop;