import { faArrowUpRightDots, faBriefcase, faCircleXmark, faCrown, faHandshake, faNotesMedical, faSackDollar, faShieldHeart } from "@fortawesome/free-solid-svg-icons"
import yamData from '../src/datas/sorosakan.json'
const dayjs = require('dayjs');

const thaiDayName = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"]
const thaiMonthName = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
const thaiMonthNameShort = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]

// STANDARD TAKSA
const taksa = [
    [1, 2, 3, 4, 7, 5, 8, 6],
    [2, 3, 4, 7, 5, 8, 6, 1],
    [3, 4, 7, 5, 8, 6, 1, 2],
    [4, 7, 5, 8, 6, 1, 2, 3],
    [5, 8, 6, 1, 2, 3, 4, 7],
    [6, 1, 2, 3, 4, 7, 5, 8],
    [7, 5, 8, 6, 1, 2, 3, 4],
    [8, 6, 1, 2, 3, 4, 7, 5],
]

const taksaModified = [
    [6, 5, 4, 1, 2, 3, 7, 8],
    [1, 8, 7, 2, 3, 4, 5, 6],
    [2, 6, 5, 3, 4, 7, 8, 1],
    [3, 1, 8, 4, 7, 5, 6, 2],
    [7, 3, 1, 5, 8, 6, 2, 4],
    [8, 7, 3, 6, 1, 2, 4, 5],
    [4, 2, 6, 7, 5, 8, 1, 3],
    [5, 4, 2, 8, 6, 1, 3, 7],
]

const taksaText = ['บริวาร ลูกน้อง ผู้ใต้บังคับบัญชา', 'สุขภาพ ความเป็นอยู่', 'อำนาจ วาสนา บารมี', 'การเงิน สิริมงคล คุณงามความดี', 'ความมั่นคง ฐานะความเป็นอยู่', 'การงาน การเรียน ความขยัน', 'ส่งเสริม เมตตา ผู้อุปการะ', 'ต้องห้าม อุปสรรค ความทุกข์ยาก']
const taksaTextModified = ['ต้องห้าม อุปสรรค ความทุกข์ยาก', 'การงาน การเรียน', 'การเงิน สิริมงคล', 'บริวาร ลูกน้อง ผู้ใต้บังคับบัญชา', 'สุขภาพ ความเป็นอยู่', 'อำนาจ วาสนา บารมี', 'ความมั่นคง ฐานะความเป็นอยู่', 'ส่งเสริม เมตตา ผู้อุปการะ']
const taksaTextModifiedShort = ['ต้องห้าม อุปสรรค', 'การงาน การเรียน', 'การเงิน สิริมงคล', 'บริวาร ลูกน้อง', 'สุขภาพ ความเป็นอยู่', 'อำนาจ บารมี', 'ความมั่นคง ', 'คนเมตตา ส่งเสริม']
const taksaIconModified = [faCircleXmark, faBriefcase, faSackDollar, faHandshake, faNotesMedical, faCrown, faArrowUpRightDots, faShieldHeart]

// MEANING of TAKSA by planet
const taksaColorText = ['แดง, ทอง', 'เหลืองอ่อน ๆ, ขาว, เทาอ่อน ๆ', 'ชมพู, บานเย็น, โอโรส', 'เขียว, เขียวใบไม้', 'ส้ม, แสด, เหลืองเข้ม', 'ฟ้า, น้ำเงิน, คราม', 'ม่วง,ม่วงเข้ม, ดำ', 'สีหม่นๆออกดำเทา แต่ไม่ดำสนิท, ทองสำริด, น้ำตาล']
const taksaDirection = ['ตะวันออกเฉียงเหนือ', 'ตะวันออก', 'ตะวันออกเฉียงใต้', 'ใต้', 'ตะวันตก', 'เหนือ', 'ตะวันตกเฉียงใต้', 'ตะวันตกเฉียงเหนือ']
const taksaColor1 = ['#d63031', '#fce8ae', '#f368e0', '#00b894', '#F79F1F', '#0984e3', '#6c5ce7', '#2d3436']
const taksaColor2 = ['#EA2027', '#fdcb6e', '#e84393', '#44bd32', '#e17055', '#0652DD', '#7d5fff', '#bdc3c7']
const taksaColor3 = ['#c23616', '#fffa65', '#ffcccc', '#006266', '#fa983a', '#273c75', '#1B1464', '#4f2801']

// YAM
const yamTimeText = {
    'day': ['06.01 - 07.30', '07.31 - 09.00', '09.01 - 10.30', '10.31 - 12.00', '12.01 - 13.30', '13.31 - 15.00', '15.01 - 16.30', '16.31 - 18.00'],
    'night': ['18.01 - 19.30', '19.31 - 21.00', '21.01 - 22.30', '22.31 - 00.00', '00.01 - 01.30', '01.31 - 03.00', '03.01 - 04.30', '04.31 - 06.00']
}

const yamMeaningText = ['ความสำเร็จ ชัยชนะ', 'โชคลาภ การได้รับ', 'ความเมตตา เอ็นดู', 'กลาง ๆ ไม่ดี ไม่ร้าย', 'เหน็ดเหนื่อย ลำบาก', 'ไม่ดี อัปมงคล', 'เสียเปรียบ']

export function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export function getThaiDayName(day) {
    return thaiDayName[day - 1]
}

export function getThaiMonthName(month) {
    return thaiMonthName[month]
}

export function thaiFullDateFormatDayJs(dayjs) {
    dayjs === null ? dayjs = dayjs() : dayjs = dayjs
    return "วัน" + thaiDayName[dayjs.day()] + "ที่ " + dayjs.date() + " " + thaiMonthName[dayjs.month()] + " " + (dayjs.year() + 543)
}

export function getfullDateFormatDayJs(day) {
    let tmpDay = day.subtract(7, 'hour')
    return tmpDay.date() + "/" + (tmpDay.month() + 1) + "/" + (tmpDay.year() + 543)
}

export function getSevenDaysObject(dayjs) {
    let sevenDaysObject = []
    for (var i = 0; i < 7; i++) {
        let newDay = dayjs().add(i, 'day')
        sevenDaysObject.push({
            "dayjs": newDay,
            "day": thaiDayName[newDay.day()],
            "date": newDay.date(),
            "month": thaiMonthNameShort[newDay.month()],
            "year": newDay.year() + 543
        })
    }

    return sevenDaysObject
}

export function getTaksabyDayJs(dayjs) {
    // FIX Wed Night
    let todayTaksa = taksaModified[(dayjs.hour() >= 18 && dayjs.day() === 3) ? 7 : dayjs.day()]
    let taksaObject = []
    todayTaksa.map((planet, index) => {
        taksaObject.push(
            {
                "meaning": taksaTextModifiedShort[index],
                "icon": taksaIconModified[index],
                "color_1": taksaColor1[planet - 1],
                "color_2": taksaColor2[planet - 1],
                "color_3": taksaColor3[planet - 1],
                "color_text": taksaColorText[planet - 1],
                "direction": taksaDirection[planet - 1]
            }
        )
    })
    return (taksaObject)
}

export function getLuckMeemon(dayjs) {
    let todayTaksa = taksa[(dayjs.hour() >= 18 && dayjs.day() === 3) ? 7 : dayjs.day()]
    return [
        todayTaksa[7],
        todayTaksa[3],
        todayTaksa[0],
        todayTaksa[4],
        todayTaksa[2],
    ]
}

export function getLuckYamMahalap(dayjs) {
    return yamData[dayjs.day() + 1]['day'][0]
}

export function getYamData(birthday, dayjs) {
    const d = dayjs.toDate()
    const td = d.getHours() < 6 ? d.getDay() == 0 ? 7 : d.getDay() : d.getDay() + 1
    // console.log('d', d.getDay(), 'h', d.getHours())

    // console.log('td', td)

    const yamAll = ['day', 'night']

    let tmpYam = {
        "day": [],
        "night": []
    }

    yamAll.map(time => {
        yamData[parseInt(td)][time].map((yams, i) => {
            yams.map((yam, index) => {
                if (yam === birthday) {
                    tmpYam[time].push({
                        "yam": i,
                        "time_text": yamTimeText[time][i],
                        "position": index,
                        "yam_meaning": yamMeaningText[index],
                        "quality": index > 3 ? -1 : index === 3 ? 0 : 1
                    })
                }
            })
        })
    })


    // console.log(tmpYam)
    return tmpYam
}