import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');


export const makeQrPaymentByOrderId = (lineAccessToken, id) => {
    const body = QueryString.stringify({ order_id: id });
    return axios.post(config.BASE_API_URL + '/payment/qr', body, {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}