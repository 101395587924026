import { Col, Row } from "react-bootstrap";
import { getSevenDaysObject } from "../utils";
import dayjs from 'dayjs'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SevenDaysBar = (gpackage) => {

    let navigate = useNavigate();

    const [sevenDays, setSevenDays] = useState([])

    useEffect(() => {
        setSevenDays(getSevenDaysObject(dayjs))
    }, []);

    const handleClickDate = (day, month, year) => {
        if (gpackage !== null) {
            navigate('/planner')

            // navigate('/planner', {
            //     state: {
            //         "targetDay": day,
            //         "targetMonth": month,
            //         "targetYear": year
            //     }
            // })
        } else {
            alert('กรุณาซื้อแพคเกจ เพื่อดูวันอื่นเพิ่มเติม')
        }
    }

    return (
        <div className="sevendays-bar-box">
            <Row>
                {
                    sevenDays.map((dayObject, index) => {
                        return (
                            <Col xs="2" key={'day_7_' + index}
                                onClick={() => handleClickDate(dayObject.dayjs.date(), dayObject.dayjs.month(), dayObject.dayjs.year())}
                                className={index === 0 ? "seven-cols seven-cols-active" : "seven-cols"}>
                                <p className="sevendays-text-day">{dayObject.day}</p>
                                <p className="sevendays-text-date">{dayObject.date}</p>
                                <p className="sevendays-text-day">{dayObject.month}</p>
                                <p className="sevendays-text-day">{dayObject.year}</p>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default SevenDaysBar;
