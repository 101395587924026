import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import Jakka from '../assets/images/jakka_gold-new.png'

import * as userHttpServices from '../services/user-http-services';
import PlanetCard from "../components/planet-card";
import TabBar from "../components/tab-bar";

function Horo() {


    const { error, liff, isLoggedIn, ready } = useLiff();
    let navigate = useNavigate();

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [planets, setPlanets] = useState([])

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    const handleClickDuang = (day, month, year) => {
        navigate('/duang')
    }

    const handleClickPackageBoxsetMenu = () => {
        navigate("/package_boxset/shop")
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
                setPlanets(response.data.data.planets)
            }
        })

    }, []);

    return (
        <>
            <TabBar selectedIndex={3} />
            {user && <div className="homeBox">
                <Row>
                    <Col xs="9" className="proflie-information-box">
                        <p className="text-name-horo">
                            ทำนายตามพื้นดวงคุณ {user.name} {user.surname} <br />
                        </p>
                        <p className="text-detail-horo">
                            {personalData && personalData.text_birth} <br />
                            {user.no_birth_time === 0 ? 'เวลา ' + user.birth_hour + ':' + (user.birth_minute.toString().length === 1 ? +'0' + user.birth_minute.toString() : user.birth_minute) + ' น.' : 'ไม่ทราบเวลาเกิด'} &nbsp;
                            {personalData && personalData.text_birth_place} <br />
                            {personalData && ('ลัคนาคุณคือ ' + personalData.text_lukkana)}
                        </p>
                    </Col>
                    <Col xs="3">
                        <img src={Jakka} onClick={handleClickDuang} width="100%" />
                        <p className="planet-card-rasi-desc">คลิก! เพื่อดูรายละเอียด</p>
                    </Col>
                </Row>
                {planets && planets.map((planet, index) => {
                    return (
                        <PlanetCard key={'planet_' + index} planet={planet} />
                    )
                })}
            </div>}
            {!gpackage && <div className="planner-buy-package">
                กรุณาซื้อแพคเกจ เพื่อดูจังหวะดาวย้าย <br /><br />
                <Button variant="primary" onClick={handleClickPackageBoxsetMenu}>
                    สั่งซื้อแพคเกจ คลิก!!
                </Button>
            </div>
            }
            <Row style={{ marginTop: 100 }}>

            </Row>
        </>
    )
}

export default Horo;
