import { useEffect, useState, useRef } from "react";
import { Col, Row, ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faHatWizard, faWandMagicSparkles, faClock, faMagnifyingGlass, faShirt } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs'
import { getThaiMonthName, thaiFullDateFormatDayJs } from '../utils';

import * as userHttpServices from '../services/user-http-services';
import SevenDaysBar from "../components/seven-days-bar";
import DailyPredictionBox from "../components/daily-prediction-box";
import SectionHeaderLine from "../components/section-header-line";
import DailyColors from "../components/daily-colors";
import DailyTiming from "../components/daily-timing";
import ProfileInformation from "../components/profile-information";
import TabBar from "../components/tab-bar";
import Jakka from "../components/jakka";

function HomeV4() {

    const { error, liff, isLoggedIn, ready } = useLiff();

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [zodiacs, setZodiacs] = useState([])
    const [monthlyPrediction, setMonthlyPrediction] = useState([])


    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
                setZodiacs(response.data.data.zodiacs)
            }
        })

        userHttpServices.getMonthlyPrediction(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setMonthlyPrediction(response.data.data)
            }
        })

    }, []);

    return (
        <>
            <TabBar selectedIndex={2} />
            {user && <div className="homeBox">
                {personalData && <ProfileInformation user={user} personalData={personalData} line={line} />}
                <Row>
                    <SevenDaysBar gpackage={gpackage} />
                    <hr className="line-section-v4" />
                </Row>
                {zodiacs.length > 0 && <Jakka planetPositions={zodiacs} />}
                <Row>
                    <Col xs="12">
                        {(zodiacs.length > 0 && monthlyPrediction.length > 0) && <div className="text-monthly">
                            คำทำนายประจำเดือน {getThaiMonthName(dayjs().month())}<br /><br />
                            {/* {parseInt(zodiacs[10] / 1800)} */}
                            {monthlyPrediction[parseInt(zodiacs[10] / 1800)].prediction_desc}
                        </div>}
                    </Col>
                </Row>
                {/* <Row>
                    <DailyPredictionBox prediction={'รายได้ลาภผลที่หวังไว้จะมีแนวโน้มโอกาสประสบผลสำเร็จตามที่ตั้งในหมายตาไว้'} />
                </Row> */}
                <Row className="section-v4">
                    <h4>สีมงคลประจำวัน <FontAwesomeIcon size="sm" icon={faShirt} color="#d7ae47" /></h4>
                    <DailyColors date={dayjs()} />
                </Row>
                <Row className="section-v4">
                    <SectionHeaderLine header={'ยามมงคลประจำวัน'} />
                    {personalData && <DailyTiming date={dayjs()} birthday={personalData.day_of_birth} isPlanner={false} />}
                </Row>

                {/* NOTES : PACKAGE ZONE */}
                {duang && <>

                    {/* กราฟชีวิต */}
                    <Row>
                        <Col xs="12" className="duangBox">
                            <p className="header-section">
                                กราฟชีวิตและคำทำนายประจำวัน <br />
                                {/* กราฟชีวิตประจำวัน <br /> */}
                            </p>
                            <p style={{ marginTop: -15, fontSize: 14, color: '#c7ac78', fontWeight: 400 }}>
                                สำหรับวันที่ {thaiFullDateFormatDayJs(dayjs())}
                            </p>
                            <div>
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_work.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การงานวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.work < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.work}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.work} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.work}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.work}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_money.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            การเงินวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.money < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.money}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.money} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.money}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.money}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_love.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            ความรักวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.love < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.love}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.love} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.love}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.love}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_health.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            สุขภาพวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.health < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.health}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.health} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.health}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.health}
                                        </p>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="2">
                                        <img src={require('../assets/images/icon_luck.png')} width="120%" />
                                    </Col>
                                    <Col xs="10">
                                        <p style={{ marginTop: 0, fontSize: 18, color: '#2c3e50', fontWeight: 400 }}>
                                            โชคลาภวันนี้ <strong style={{ fontSize: 20, color: duang.graph.percent.luck < 50 ? '#FF6D59' : '#98D98E' }}>{duang.graph.percent.luck}%</strong>
                                        </p>
                                        <ProgressBar style={{ height: '0.5rem' }} now={duang.graph.percent.luck} />
                                        <hr className="line-section" />
                                        <p style={{ marginTop: 0, fontSize: 14, color: '#2c3e50', fontWeight: 400 }}>
                                            {duang.graph.description.luck}<br />
                                            อาจจะได้เกี่ยวข้องกับ : {duang.graph.keyword.luck}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </>}
                <Row style={{ marginTop: 100 }}>

                </Row>
            </div >}
        </>
    )
}

export default HomeV4;
