import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';

import GNavbar from "../../components/navbar";

import * as wallpaperHttpServices from "../../services/wallpaper-http-services";
import * as packageHttpServices from "../../services/package-http-services";
import * as orderHttpServices from "../../services/order-http-services";
import * as userHttpServices from '../../services/user-http-services';


function OrderConfirm() {

    // let { wallpaperId } = useParams();

    const { state: order } = useLocation()
    const { wallpaper_id, package_id, boxset_id, card_id, type } = order

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();
    const { error, liff, isLoggedIn, ready } = useLiff();

    const [wallpaper, setWallpaper] = useState({})
    const [gpackage, setGPackage] = useState({})
    const [simulateOrder, setSimulateOrder] = useState({})
    const [discountCode, setDiscountCode] = useState('')
    const [verifyDiscountCode, setVerifyDiscountCode] = useState(true)
    const [partnerName, setPartnerName] = useState('')
    const [productTitle, setProductTitle] = useState('')
    const [user, setUser] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [disable, setDisable] = useState(false)
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = () => setShowConfirmModal(true)

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    }

    const handleClickCheckout = () => {
        setDisable(true)
        if (verifyDiscountCode) {
            console.log(order)
            console.log('address', address)
            console.log('phone', phone)
            if (type === 3) {

                if (address === '') {
                    setDisable(false)
                    alert('กรุณากรอกรายละเอียดที่อยู่ ที่ต้องการให้จัดส่ง')
                }

                if (phone == '') {
                    setDisable(false)
                    alert('กรุณากรอกเบอร์โทรศัพท์ ที่ต้องการให้ติดต่อ')
                }
                if (address !== '' && phone !== '') {
                    orderHttpServices.checkoutOrder(lineAccessToken, { ...order, phone_number: phone, address: address })
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                console.log(response.data.order.order_id)
                                setDisable(false)
                                navigate("/order/" + response.data.order.order_id)
                            } else {
                                alert('ไม่สามารถสั่งซื้อได้ กรุณาติดต่อเจ้าหน้าที่')
                            }
                        })
                }
            } else {
                orderHttpServices.checkoutOrder(lineAccessToken, { ...order, discount_code: discountCode.toLocaleUpperCase() })
                    .then((response) => {
                        // console.log(response.data)
                        if (response.data.success) {
                            console.log(response.data.order.order_id)
                            setDisable(false)
                            navigate("/order/" + response.data.order.order_id)
                        } else {
                            alert('ไม่สามารถสั่งซื้อได้ กรุณาติดต่อเจ้าหน้าที่')
                        }
                    })
            }
        } else {
            alert('โค้ดส่วนลดไม่ถูกต้อง กรุณาตรวจสอบโค้ดส่วนลด')
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken)
            .then((response) => {
                if (response.data.success) {
                    console.log('user', response.data)
                    setUser(response.data.data.user)
                    setPersonalData(response.data.data.personal_data)
                }
            })

        if (type === 0) {
            setProductTitle('Wallpaปัง')
            wallpaperHttpServices.getWallpaperById(wallpaper_id)
                .then((response) => {
                    let wallpaper = response.data.data
                    setWallpaper(wallpaper)
                })
        } else if (type === 1) {
            setProductTitle('แพคเกจ')
            packageHttpServices.getPackageDetailById(package_id)
                .then((response) => {
                    setGPackage(response.data.data)
                })
        } else if (type === 2) {
            setProductTitle(' Boxset ')
            packageHttpServices.getPackageBoxsetDetailById(boxset_id)
                .then((response) => {
                    setGPackage(response.data.data)
                })
        } else if (type === 3) {
            setProductTitle(' การ์ดหอมเสริมดวง ')
            packageHttpServices.getCardDetailById(card_id)
                .then((response) => {
                    setGPackage(response.data.data)
                })
        }

        orderHttpServices.simulateOrder(lineAccessToken, order)
            .then((response) => {
                // console.log(response.data)
                setSimulateOrder(response.data.order)
            })

    }, []);

    const handleOnValueChange = (e) => {
        let id = e.target.id
        if (id === "discountCode") {
            setDiscountCode(e.target.value)
            if (e.target.value === "") {
                setVerifyDiscountCode(true)
            } else {
                setVerifyDiscountCode(false)
                let discountCode = e.target.value + "".toUpperCase()
                let wid = wallpaper_id === undefined ? '' : wallpaper_id
                if (type === 2) {
                    wid = boxset_id
                } else if (type === 3) {
                    wid = card_id
                }
                orderHttpServices.getDiscountCode(discountCode, wid, user.user_id)
                    .then(response => {
                        setVerifyDiscountCode(response.data.success)
                        response.data.success && setPartnerName(response.data.data.partner_name)
                        orderHttpServices.simulateOrder(lineAccessToken, { ...order, discount_code: discountCode, user_id: user.user_id })
                            .then((response) => {
                                console.log('simm', response.data)
                                setSimulateOrder(response.data.order)
                            })
                    })
            }
        }
    }

    return (
        <>
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันคำสั่งซื้อ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 18 }}>
                        {(user && user.wallpaper_quota > 0 && type === 0) ? 'เมื่อกดยืนยันระบบจะทำการหักยอดสิทธิการสร้างคงเหลือ โดยหัก 1 ครั้ง จาก ' + user.wallpaper_quota + ' ครั้ง' : 'เมื่อกดยืนยัน จะเข้าสู่หน้าชำระเงินด้วย QR Code'}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClickCheckout} disabled={disable}>
                        ยืนยันคำสั่งซื้อ
                    </Button>
                </Modal.Footer>
            </Modal>

            <GNavbar title={"ตรวจสอบการสั่งซื้อ" + productTitle} />
            <div className="wallpaperBg">
                <div className="wallpaperBox">
                    <Row>
                        <Col xs="3">
                            <img src={type === 0 ? wallpaper.thumbnail_img : gpackage.image} className="wallpaperThumbnail" />
                        </Col>
                        <Col xs="9">
                            <p className="wallpaperOrderHeaderText">
                                คุณกำลังสั่งซื้อ{productTitle}
                            </p>
                            <h4>
                                {wallpaper.name}{gpackage.package_name}{gpackage.boxset_name}{gpackage.card_name}
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="wallpaperOrderSummary">
                            <h1 className="wallpaperOrderSummaryHeader">สรุปรายการสั่งซื้อ</h1>

                            {type === 0 && <strong>รายการWallpaปัง<br /><br /></strong>}

                            {(simulateOrder['wallpaper'] || simulateOrder['package'] || simulateOrder['boxset'] || simulateOrder['card']) && (<Row>
                                <Col xs="8">
                                    {simulateOrder['wallpaper'] && simulateOrder['wallpaper']['name']}
                                    {simulateOrder['package'] && simulateOrder['package']['package_name']}
                                    {simulateOrder['boxset'] && simulateOrder['boxset']['boxset_name']}
                                    {simulateOrder['card'] && simulateOrder['card']['card_name']}
                                </Col>
                                <Col xs="4" style={{ textAlign: 'right' }}>
                                    {simulateOrder['wallpaper'] && Number(simulateOrder['wallpaper']['price']).toFixed(2)}
                                    {simulateOrder['package'] && Number(simulateOrder['package']['price']).toFixed(2)}
                                    {simulateOrder['boxset'] && Number(simulateOrder['boxset']['price']).toFixed(2)}
                                    {simulateOrder['card'] && Number(simulateOrder['card']['price']).toFixed(2)}
                                </Col>
                                <hr />
                            </Row>)
                            }

                            {simulateOrder['toppings'] && (<strong>รายการท็อปปิ้งเสริมดวง<br /><br /></strong>)}

                            {simulateOrder['toppings'] && (simulateOrder['toppings'].map((topping, i) => {
                                return (
                                    <Row key={i}>
                                        <Col xs="8">
                                            {topping['name']}
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {Number(topping['price']).toFixed(2)}
                                        </Col>
                                        <hr />
                                    </Row>
                                )
                            }))
                            }
                            <br />
                            {(simulateOrder['wallpaper'] || simulateOrder['package'] || simulateOrder['boxset'] || simulateOrder['card']) && (
                                <>
                                    <Row>
                                        <Col xs="8">
                                            <strong>รวมทั้งหมด</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong>{(user && user.wallpaper_quota > 0 && type === 0) ? '0.00' : Number(simulateOrder['total_price']).toFixed(2)}</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <strong>ส่วนลดจากโค้ด: {simulateOrder['discount_code']} {simulateOrder['discount_percent'] !== undefined ? ('(' + simulateOrder['discount_percent'] + '%)') : ''}</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong>{Number(simulateOrder['discount']).toFixed(2)}</strong>
                                        </Col>
                                        <hr />
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <strong style={{ fontSize: 18 }}>ราคาที่ต้องชำระรวมส่วนลด</strong>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <strong style={{ fontSize: 18 }}>{(user && user.wallpaper_quota > 0 && type === 0) ? '0.00' : (Number(simulateOrder['total_price']) - Number(simulateOrder['discount'])).toFixed(2)}</strong>
                                        </Col>
                                        <hr />
                                        {(user && user.wallpaper_quota > 0 && type === 0) ? '* สร้างโดยหักจากสิทธิการสร้างคงเหลือ หัก 1 ครั้ง จาก ' + user.wallpaper_quota + ' ครั้ง' : ''}
                                    </Row>
                                </>
                            )}


                        </Col>
                    </Row>

                    {(user && personalData && type === 2 && boxset_id === 6) && <Row>
                        <Col xs="12" className="wallpaperOrderSummary">
                            <p>
                                <strong>พิธีจุดเทียน “ราหูหนุนดวง” </strong><br />
                                ให้กับคุณ {user.name + " " + user.surname}<br />
                                {personalData.text_birth}<br /><br />
                                ทำพิธีตามศาสตร์ล้านนาโบราณ<br />
                                โดยพระมหากิตติพงษ์ สัจจัญญู (มหาลาภ)<br />
                                พร้อมปลุกเสกยันต์ที่มีชื่อและดวงชะตาคุณโดยเฉพาะ
                                <br /><br />
                                พิธีจะจัดขึ้นวันที่ 22 ต.ค. 2566
                                “ซึ่งตรงกับวันอาทิตย์ขึ้น 8 ค่ำ เดือน 11  ปีเถาะ พ.ศ. 2566 จ.ศ. 1385
                                ในวันนี้ตามหลักโหราศาสตร์ไทใหญ่ วันอาทิตย์ที่ 22  เดือน 11 ไต
                                เป็นวันนักฆาต (นักขัตฤกษ์) ตาแลงสองขอน
                                ก่ามจุ่งเป้อะมังกะล่า ชนะอวมงคลทั้งปวง
                                เป็นวันนี้ถือเป็นวันที่ดีมากเพื่อประกอบพิธีมงคลนี้”<br /><br />
                                * หลังจากเสร็จสิ้นพิธีทางทีมงานจะจัดส่งรูปพิธีการจุดเทียนของคุณให้อีกครั้ง
                            </p>
                        </Col>
                    </Row>}

                    {!(user && user.wallpaper_quota > 0 && (type === 0 || type === 3)) && <Row>
                        <Col xs="12" className="wallpaperOrderSummary">
                            <h1 className="wallpaperOrderSummaryHeader">โค้ดส่วนลด/คูปองสินค้า</h1>
                            <Form.Control size="sm" placeholder="กรุณากรอกโค้ดส่วนลด" onChange={(handleOnValueChange)} id="discountCode" defaultValue={discountCode} />
                            {discountCode !== "" && <p className="regisReferralCodeText">
                                {verifyDiscountCode ? <strong style={{ color: 'green' }}>รหัสผู้แนะนำถูกต้อง : {partnerName}</strong> : <><strong style={{ color: 'red' }}>รหัสผู้แนะนำไม่ถูกต้อง</strong></>}
                            </p>
                            }
                        </Col>
                    </Row>}

                    {(type === 3) && <Row>
                        <Col xs="12" className="wallpaperOrderSummary">
                            <p>กรุณากรอกรายละเอียดที่อยู่สำหรับจัดส่ง<br />(สินค้าจัดส่ง 19/05/2566)</p>
                            <Form.Control as="textarea" rows={4} onChange={handleChangeAddress} defaultValue={address} />
                            <br />
                            <p>กรุณากรอกเบอร์โทรศัพท์สำหรับติดต่อ</p>
                            <Form.Control onChange={handleChangePhone} defaultValue={phone} />
                        </Col>
                    </Row>}

                    <Row style={{ marginTop: 20 }}>
                        <Button onClick={handleShowConfirmModal}>
                            {"สั่งซื้อ" + productTitle}
                        </Button>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default OrderConfirm;