import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { LiffProvider } from 'react-liff';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';

import App from './App';
import Register from './pages/register';
import Home from './pages/home';
import Horacle from './pages/horacle';
import WallpaperShop from './pages/wallpaper/wallpaper-shop';
import WallpaperItem from './pages/wallpaper/wallpaper-item';
import WallpaperCheckout from './pages/wallpaper/wallpaper-checkout';
import OrderConfirm from './pages/order/order-confirm';
import OrderLists from './pages/order/order-lists';
import OrderDetail from './pages/order/order-detail';
import PackageShop from './pages/package/package-shop';
import PackageBoxsetShop from './pages/package/package-boxset-shop';
import HomeV4 from './pages/homev4';
import Planner from './pages/planner';
import Horo from './pages/horo';
import Luck from './pages/luck';
import Shop from './pages/shop';
import Duang from './pages/duang';

ReactDOM.render(
  <LiffProvider liffId='1656990127-Mj82gmPz' stubEnabled={false}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/home_old" element={<Home />} />
          <Route path="/home" element={<HomeV4 />} />
          <Route path="/planner" element={<Planner />} />
          <Route path="/horo" element={<Horo />} />
          <Route path="/luck" element={<Luck />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/duang" element={<Duang />} />
          <Route path="/register" element={<Register />} />
          <Route path="/horacle" element={<Horacle />} />
          <Route path="/order/lists" element={<OrderLists />} />
          <Route path="/order/:orderId" element={<OrderDetail />} />
          <Route path="/order/confirm" element={<OrderConfirm />} />
          <Route path="/package/shop" element={<PackageShop />} />
          <Route path="/package_boxset/shop" element={<PackageBoxsetShop />} />
          <Route path="/wallpaper/shop" element={<WallpaperShop />} />
          <Route path="/wallpaper/item/:wallpaperId" element={<WallpaperItem />} />
          <Route path="/wallpaper/checkout" element={<WallpaperCheckout />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </LiffProvider>,
  document.getElementById('root')
);
