import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CarouselPromotion = ({ header }) => {

    let navigate = useNavigate();

    const handleClickWallpaperItem = (id) => {
        navigate("/wallpaper/item/" + id)
    }

    const handleClickPackageCarousel = () => {
        navigate("/package/shop/")
    }

    return (
        <Carousel>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(34)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss4/carousel_wukong.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(19)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_3.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(18)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_1.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(17)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss3/ss3_carousel_2.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(11)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_5.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(10)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_4.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(4)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_1.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={() => handleClickWallpaperItem(5)}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_2.jpg"
                />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    onClick={handleClickPackageCarousel}
                    className="d-block w-100"
                    src="https://ganesha168.sgp1.digitaloceanspaces.com/wallpapers/carousels/ss2/ss_2_carousel_3.jpg"
                />
            </Carousel.Item>
        </Carousel>
    )
}

export default CarouselPromotion;