import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const lineLogin = (lineAccessToken) => {
    return axios.post(config.BASE_API_URL + '/user/line_login', {}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    });
};

export const lineRegister = (lineAccessToken, data) => {
    const body = QueryString.stringify(data);
    return axios.post(config.BASE_API_URL + '/user/line_register', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    });
};

export const getUser = (lineAccessToken) => {
    return axios.get(config.BASE_API_URL + '/user/get_user', {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const checkPhoneNumber = (lineAccessToken) => {
    return axios.get(config.BASE_API_URL + '/user/check_phone_number', {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const editPhoneNumber = (lineAccessToken, data) => {
    return axios.put(config.BASE_API_URL + '/user/edit_phone_number', data, {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const dailyHoracle = (lineAccessToken) => {
    return axios.get(config.BASE_API_URL + '/user/dialy_horo', {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const getMonthlyPrediction = (lineAccessToken) => {
    return axios.get(config.BASE_API_URL + '/zodiac/get', {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}