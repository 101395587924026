import { Button, Col, Form, Row } from "react-bootstrap";
import { getSevenDaysObject, thaiFullDateFormatDayJs, padLeadingZeros, getYamData } from "../utils";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faMoon, faStar, faSun } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

const DailyTiming = ({ date, birthday, isPlanner = true, isBlink = true }) => {

    const yamAll = ['day', 'night']
    const planYamActivities = [
        {
            "id": 0,
            "activity": "เจรจากับลูกค้าหรือผู้ใหญ่แล้วเมตตาเอ็นดู",
            "type": 2
        },
        {
            "id": 1,
            "activity": "เสี่ยงโชค เสี่ยงดวง ได้ตามที่หวัง",
            "type": 1
        },
        {
            "id": 2,
            "activity": "ต้องการความสำเร็จ ชัยชนะ",
            "type": 0
        },
        {
            "id": 3,
            "activity": "ง้อแฟนงอนหรือขอคืนดี สำเร็จ",
            "type": 2
        },
        {
            "id": 4,
            "activity": "ติดต่อธุรกิจสำเร็จผลตามที่หวัง",
            "type": 1
        },
        {
            "id": 5,
            "activity": "ขอความช่วยเหลือจากผู้อื่นให้เมตตามาช่วย",
            "type": 2
        },
        {
            "id": 6,
            "activity": "เจรจาต่อรองให้ได้เปรียบ",
            "type": 0
        },
        {
            "id": 7,
            "activity": "เจรจาให้ได้เงินตามที่คาดหวัง",
            "type": 1
        },
        {
            "id": 8,
            "activity": "นำเสนอผลงานสุดปัง ปิดการขาย",
            "type": 0
        },
        {
            "id": 9,
            "activity": "ศัลยกรรมสุดปัง",
            "type": 0
        },
        {
            "id": 10,
            "activity": "ไหว้พระขอพร",
            "type": 2
        }
    ]



    const [yamData, setYamData] = useState({ 'day': [], 'night': [] })
    const [currentSaoYam, setCurrentSaoYam] = useState(-1)
    const [currentTimeDN, setCurrentTimeDN] = useState('day')
    const [selectedPlanYamActivityValue, setSelectedPlanYamActivityValue] = useState(-1)
    const [suggestYam, setSuggestYam] = useState({
        "day": [-1, -1, -1, -1, -1, -1, -1],
        "night": [-1, -1, -1, -1, -1, -1, -1]
    })

    const handleOnValueChange = (e) => {
        let id = e.target.id
        if (id === "planYamActivity") {
            let suggestedYamType = planYamActivities[e.target.value].type
            let newSuggestYam = {
                "day": [-1, -1, -1, -1, -1, -1, -1],
                "night": [-1, -1, -1, -1, -1, -1, -1]
            }
            yamAll.map(time => {
                yamData[time].map((yam, index) => {
                    if (yam.position === suggestedYamType) {
                        newSuggestYam[time][index] = suggestedYamType
                    }
                })
            })
            setSuggestYam(newSuggestYam)
            setSelectedPlanYamActivityValue(e.target.value)
        }
    }

    useEffect(() => {
        // console.log(date, birthday)
        setYamData(getYamData(birthday, date))
    }, [date]);

    useEffect(() => {
        if (isBlink) {
            const intervalId = setInterval(() => {
                let dt = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Bangkok" }))
                let secs = dt.getSeconds() + (60 * (dt.getMinutes() + (60 * dt.getHours())))

                let dn = "day"
                let saoYam = 0

                if (secs >= 0 && secs < 21600) {
                    dn = "night"
                    saoYam = parseInt(secs / 5400) + 4
                } else if (secs >= 21600 && secs < 64800) {
                    dn = "day"
                    saoYam = parseInt(secs / 5400) - 4
                } else if (secs >= 64800 && secs < 86400) {
                    dn = "night"
                    saoYam = parseInt(secs / 5400) - 12
                }

                setCurrentSaoYam(saoYam)
                setCurrentTimeDN(dn)
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, []);

    return (
        <>
            {isPlanner && <Col xs="12">
                <Form.Group className="mb-2">
                    <Form.Label style={{ marginTop: 0, fontSize: 14, color: '#c7ac78' }}>กิจกรรมที่จะวางแผน</Form.Label>
                    <Form.Select onChange={(handleOnValueChange)} defaultValue={selectedPlanYamActivityValue} id="planYamActivity" size="sm">
                        <option value={-1} disabled>กรุณาเลือกกิจกรรมที่จะวางแผน</option>
                        {
                            planYamActivities.map((activity) => {
                                return <option key={activity.id} value={activity.id}>{activity.activity}</option>
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Col>}
            {
                yamAll.map(time => {
                    return (
                        <div key={'time_' + time} className="yam-box" style={{ backgroundColor: time === 'day' ? '#f8c291' : '#3c6382' }}>
                            <h6 style={{ color: time === 'day' ? '' : '#ffffff' }}>ยาม{time === 'day' ? 'กลางวัน' : 'กลางคืน'} {thaiFullDateFormatDayJs(date)}</h6>
                            <div className="yam-icon" style={{ backgroundColor: time === 'day' ? '#e74c3c' : '#2c3e50' }}>
                                <FontAwesomeIcon icon={time === 'day' ? faSun : faMoon} />
                            </div>
                            {
                                yamData[time].map((yam, index) => {
                                    return (
                                        <Row key={'yam_' + time + '_' + index} className={"yam-line yam-" + yam['quality'] + " " + ((time === currentTimeDN && index === currentSaoYam) ? "yam-alert" : "")}>
                                            <Col xs="1">
                                                {suggestYam[time][index] > -1 ? <FontAwesomeIcon className="iconSuggestedYam" icon={faStar} color={'#ffffff'} /> : ''}
                                            </Col>
                                            <Col xs="5">
                                                {yam['time_text']}
                                            </Col>
                                            <Col xs="6">
                                                {yam['yam_meaning']}
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </>
    )
}

export default DailyTiming;