import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import GNavbar from '../../components/navbar';

import NewWallpaperIcon from '../../assets/images/new_wallpaper_icon.png'
import FreeWallpaperIcon from '../../assets/images/free_wallpaper_icon.png'
import ComingSoonWallpaperIcon from '../../assets/images/cms_wallpaper_icon.png'
import DiscountWallpaperIcon from '../../assets/images/pro_wallpaper_icon.png'

import * as wallpaperHttpServices from "../../services/wallpaper-http-services";
import * as userHttpServices from '../../services/user-http-services';
import CarouselPromotion from "../../components/carousel-promotion";

function WallpaperShop() {

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();

    const [wallpaperCategories, setWallpaperCategories] = useState([])
    const [user, setUser] = useState(null)

    const handleClickWallpaper = (e) => {
        navigate("/wallpaper/item/" + e.target.id)
    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken)
            .then((response) => {
                if (response.data.success) {
                    setUser(response.data.data.user)
                }
            })


        wallpaperHttpServices.getWallpaperWithCategories()
            .then((response) => {
                let wallpapers_cat = response.data.data
                setWallpaperCategories(wallpapers_cat)
            })
    }, []);


    return (
        <>
            <GNavbar title="เลือกWallpaปังที่คุณต้องการสร้าง" navigation={"/shop"} />
            <div className="wallpaperBg">
                <CarouselPromotion />
                <div className="wallpaperBox">
                    {user && user.wallpaper_quota > 0 && <div style={{ color: '#c7ac78', position: 'relative', top: 0, left: 0, textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 20, borderRadius: 6, border: '1px solid #c7ac78' }}>
                        คุณสามารถสร้าง Wallpaปัง ได้อีก {user && user.wallpaper_quota} รูป
                    </div>}
                    {
                        wallpaperCategories.map((category) => {
                            if (category.active === 1) {
                                return (
                                    <div key={category.cate_id}>
                                        <h3 className="wallpaperCategoryHeader">{category.name}</h3>
                                        <p style={{ color: '#2c3e50', textAlign: 'center' }}>{category.description}</p>
                                        <Row>
                                            {
                                                category.wallpapers.map((wallpaper) => {
                                                    if (wallpaper.show !== 0) {
                                                        return (
                                                            <Col key={wallpaper.wid} xs="6" className="wallpaperContainer" onClick={handleClickWallpaper}>
                                                                <img id={wallpaper.wid} src={wallpaper.thumbnail_img} className={wallpaper.active === 1 ? "wallpaperThumbnail" : "wallpaperThumbnailNotActive"} />
                                                                {wallpaper.status === 1 && <img src={NewWallpaperIcon} id={wallpaper.wid} className="wallpaperNewIcon" />}
                                                                {wallpaper.status === 2 && <img src={FreeWallpaperIcon} id={wallpaper.wid} className="wallpaperNewIcon" />}
                                                                {wallpaper.status === 3 && <img src={ComingSoonWallpaperIcon} id={wallpaper.wid} className="wallpaperNewIcon" />}
                                                                {wallpaper.status === 4 && <img src={DiscountWallpaperIcon} id={wallpaper.wid} className="wallpaperNewIcon" />}
                                                                <div id={wallpaper.wid} className="wallpaperOverlay">{wallpaper.name}</div>
                                                            </Col>
                                                        )
                                                    }
                                                })
                                            }
                                        </Row>
                                    </div>
                                )
                            }
                        })
                    }
                    {/* <Row>
                        {
                            wallpapers.map((wallpaper) => {
                                return (
                                    <Col key={wallpaper.wid} xs="6" className="wallpaperContainer" onClick={handleClickWallpaper}>
                                        <img id={wallpaper.wid} src={wallpaper.thumbnail_img} className={wallpaper.active === 1 ? "wallpaperThumbnail" : "wallpaperThumbnailNotActive"} />
                                        {wallpaper.status === 1 && <img src={NewWallpaperIcon} className="wallpaperNewIcon" />}
                                        {wallpaper.status === 2 && <img src={FreeWallpaperIcon} className="wallpaperNewIcon" />}
                                        {wallpaper.status === 3 && <img src={ComingSoonWallpaperIcon} className="wallpaperNewIcon" />}
                                        {wallpaper.status === 4 && <img src={DiscountWallpaperIcon} className="wallpaperNewIcon" />}
                                        <div id={wallpaper.wid} className="wallpaperOverlay">{wallpaper.name}</div>
                                    </Col>
                                )
                            })
                        }
                    </Row> */}
                </div>
            </div>
        </>
    )
}

export default WallpaperShop;