import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const getAllPackages = () => {
    return axios.get(config.BASE_API_URL + '/packages')
};

export const getPackageDetailById = (pid) => {
    return axios.get(config.BASE_API_URL + '/package/' + pid)
};

export const getAllPackageBoxsets = () => {
    return axios.get(config.BASE_API_URL + '/packages_boxset')
};

export const getPackageBoxsetDetailById = (pid) => {
    return axios.get(config.BASE_API_URL + '/package_boxset/' + pid)
};

export const getCardDetailById = (pid) => {
    return axios.get(config.BASE_API_URL + '/card/' + pid)
};