import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const getDiscountCode = (discountCode, wallpaper_id ,user_id) => {
    return axios.get(config.BASE_API_URL + '/order/discount_code/' + discountCode + '?wallpaper_id=' + wallpaper_id +  '&user_id='+user_id )
};

export const getUserOrders = (lineAccessToken) => {
    return axios.get(config.BASE_API_URL + '/order/user_order', {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const getOrderDetailById = (lineAccessToken, id) => {
    return axios.get(config.BASE_API_URL + '/order/order_detail/' + id, {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken
        }
    })
}

export const simulateOrder = (lineAccessToken, data) => {
    const body = QueryString.stringify(data);
    return axios.post(config.BASE_API_URL + '/order/simulate_order', body, {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

export const checkoutOrder = (lineAccessToken, data) => {
    const body = QueryString.stringify(data);
    return axios.post(config.BASE_API_URL + '/order/checkout', body, {
        headers: {
            'Authorization': 'Bearer ' + lineAccessToken,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}