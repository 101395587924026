import { useEffect, useState } from "react";
import { Row, Col, Carousel, Modal, Button } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';

import GNavbar from "../../components/navbar";

import * as wallpaperHttpServices from "../../services/wallpaper-http-services";
import * as userHttpServices from '../../services/user-http-services';

import ImgTDMSLogo from '../../assets/images/payment_tdms.png'

function WallpaperCheckout() {

    // let { wallpaperId } = useParams();

    const { state } = useLocation()
    const { wallpaper_id } = state

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    let navigate = useNavigate();
    const { error, liff, isLoggedIn, ready } = useLiff();

    const [wallpaper, setWallpaper] = useState({})
    const [user, setUser] = useState(null)

    const [showComingSoonModal, setShowComingSoonModal] = useState(false)
    const handleCloseComingSoonModal = () => setShowComingSoonModal(false)
    const handleShowComingSoonModal = () => setShowComingSoonModal(true)

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const handleShowConfirmModal = () => setShowConfirmModal(true)

    const handleClickCreateWallpaper = () => {

    }

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken)
            .then((response) => {
                if (response.data.success) {
                    setUser(response.data.data.user)
                }
            })

        wallpaperHttpServices.getWallpaperById(wallpaper_id)
            .then((response) => {
                let wallpaper = response.data.data
                setWallpaper(wallpaper)
            })

    }, []);

    return (
        <>
            <Modal show={showComingSoonModal} onHide={handleCloseComingSoonModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยังไม่เปิดให้บริการ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 14 }}>
                        Wallpaปังรูปนี้ยังไม่เปิดให้บริการ กำลังจะมีให้ใช้ได้เร็ว ๆ นี้
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseComingSoonModal}>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันสร้างWallpaปัง</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: 400, fontSize: 14 }}>
                        ยืนยันสร้างWallpaปัง <br /><strong>{wallpaper.name}</strong> <br />
                        {(user.wallpaper_quota === 0 || user.wallpaper_quota === null) ? '' : 'โดยหักจากสิทธิการสร้างคงเหลือ 1/' + user.wallpaper_quota + ' ครั้ง'} <br />
                        เมื่อสร้างเสร็จแล้วWallpaปังจะถูกส่งเข้าทางไลน์โดยอัตโนมัติ หรือ สามารถเข้าไปกดเพื่อบันทึกรูปเองได้ในเมนู 'รายการสั่งซื้อของคุณ'
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClickCreateWallpaper}>
                        ยืนยันสร้างWallpaปัง
                    </Button>
                </Modal.Footer>
            </Modal>

            <GNavbar title="กรุณาชำระเงินและเสร็จสิ้นคำสั่งซื้อ" />
            <div className="wallpaperBg">
                <div className="wallpaperBox">
                    <Row>
                        <Col xs="6">
                            <img src={wallpaper.thumbnail_img} className="wallpaperThumbnail" />
                        </Col>
                        <Col xs="6">
                            <h4>
                                {wallpaper.name}
                            </h4>
                            <p className="wallpaperDescriptionText">
                                {wallpaper.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="wallpaperShortDescription">
                            <img className="wallpaperImage" src={ImgTDMSLogo} />
                            <img className="wallpaperImage" src="" />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Button onClick={wallpaper.active === 1 ? handleShowConfirmModal : handleShowComingSoonModal} disabled={wallpaper.active === 0 ? true : false}>
                            สั่งซื้อWallpaปัง
                            {/* {wallpaper.active === 1 ? 'สร้าง Wallpaper เสริมดวง' : 'เปิดให้บริการเร็ว ๆ นี้ '}  {wallpaper.price === 0 ? 'ฟรี!' : wallpaper.price + ' บาท'} */}
                        </Button>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default WallpaperCheckout;