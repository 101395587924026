import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import * as userHttpServices from '../services/user-http-services';
import GNavbar from "../components/navbar";


function Horacle() {

    const { error, liff, isLoggedIn, ready } = useLiff()
    const [dailyCard, setDailyCard] = useState([])
    const [header, setHeader] = useState([
        "เรื่องราวที่ผ่านมา", "สิ่งที่คุณกำลังเผชิญ", "สิ่งที่กำลังจะเกิดขึ้น"
    ])
    let navigate = useNavigate();

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }
    }, []);

    useEffect(() => {
        userHttpServices.dailyHoracle(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setDailyCard(response.data.data)
            }
        })
    }, []);

    return (
        <>
            <GNavbar title="ไพ่ทำนายรายวันสำหรับคุณ" navigation={"/luck"} />
            <Row className="horacle-cards-area">
                {
                    dailyCard.map((card, index) => {
                        return (
                            <Col key={'card' + index} xs="4" className="horacle-card-box">
                                <img src={card.img_url} width={'100%'} />
                            </Col>
                        )
                    })
                }
            </Row>
            {
                dailyCard.map((card, index) => {
                    return (
                        <Row key={'desc' + index} className="horacle-desc-area">
                            <strong>{header[index]}</strong>
                            <p>
                                {
                                    card.oracle
                                }
                            </p>
                        </Row>
                    )
                })
            }
        </>
    )
}

export default Horacle;
