import { useEffect, useState, useRef } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink, Accordion } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLiff } from 'react-liff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import * as userHttpServices from '../services/user-http-services';
import GNavbar from "../components/navbar";

import ProfileInformation from "../components/profile-information";
import { getThaiMonthName } from "../utils";
import dayjs from "dayjs";
import Jakka from "../components/jakka";

function Duang() {

    const { error, liff, isLoggedIn, ready } = useLiff();

    let navigate = useNavigate();

    const [user, setUser] = useState(null)
    const [line, setLine] = useState(null)
    const [gpackage, setGPackage] = useState(null)
    const [duang, setDuang] = useState(null)
    const [personalData, setPersonalData] = useState(null)
    const [zodiacs, setZodiacs] = useState([])

    const [monthlyPrediction, setMonthlyPrediction] = useState([])

    const { lineAccessToken, userData, userLineData } = useSelector(
        state => state.userReducer,
    );

    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATION === 'true' && lineAccessToken === '') {
            navigate("/")
            return
        }

        userHttpServices.getUser(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setUser(response.data.data.user)
                setLine(response.data.data.line)
                setDuang(response.data.data.duang)
                setPersonalData(response.data.data.personal_data)
                setGPackage(response.data.data.user.package_id)
                setZodiacs(response.data.data.zodiacs)
            }
        })

        userHttpServices.getMonthlyPrediction(lineAccessToken).then((response) => {
            if (response.data.success) {
                // console.log(response.data.data)
                setMonthlyPrediction(response.data.data)
            }
        })

    }, []);

    return (
        <>
            <GNavbar title="รายละเอียดพื้นดวงของคุณ" navigation={"/horo"} />
            <div className="homeBox">
                <Row>
                    {personalData && <ProfileInformation user={user} personalData={personalData} line={line} />}
                </Row>
                {zodiacs.length > 0 && <Jakka planetPositions={zodiacs} />}
                <Row>
                    <Col xs="12">
                        <p className="text-lukkana">
                            {personalData && ('ลัคนาคุณคือ ' + personalData.text_lukkana)}
                        </p>
                    </Col>
                    <Col xs="12">
                        {(zodiacs.length > 0 && monthlyPrediction.length > 0) && <div className="text-monthly">
                            คำทำนายประจำเดือน {getThaiMonthName(dayjs().month())}<br /><br />
                            {/* {parseInt(zodiacs[10] / 1800)} */}
                            {monthlyPrediction[parseInt(zodiacs[10] / 1800)].prediction_desc}
                        </div>}
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default Duang;
