import { Navbar, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import backIcon from '../assets/images/back_icon.png'

const GNavbar = ({ title, navigation }) => {

    let navigate = useNavigate();

    const handleClickBack = () => {
        if (navigation === undefined) {
            navigate(-1)
        } else {
            navigate(navigation)
        }
    }

    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand className="navBar">
                    <img
                        src={backIcon}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        onClick={handleClickBack}
                    />{' '}
                    {title}
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export default GNavbar;